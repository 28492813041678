import React from 'react';
import { Button, Modal } from "react-bootstrap";
import styles from '../../styles/Revisao.module.scss';

const ModalPendenciaRevisao = (props) => {
  return (
    <Modal show={props.show} centered size='lg'>
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title>Mensagem de aviso</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalPendencia}>
            <article>
              <h5 className="mt-1">
                <span className="text-danger">ATENÇÃO:</span> A revisão do material <span className="text-decoration-underline descMaterial">{props.obj.descricaomaterial}</span> será iniciada.
              </h5>
              <h5 className="mt-1">Ao finalizar, <span>PENDENCIE</span> para o laboratório.</h5>
            </article>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button className="btn-lg shadow-none" style={{ width: "200px" }} onClick={props.closeHandler} variant="primary">
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPendenciaRevisao;