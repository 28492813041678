import React from 'react';
import Tabela from '../../Misc/Tabela/Tabela';
import TooltipComponent from '../../Misc/Tooltip/TooltipComponent';
import MaterialIcon from 'material-icons-react';
import { Button } from 'react-bootstrap';

import { useTriangulacao } from '../../../contexts/MP/TriangulacaoContext'; 


const TableInfos = () => {

    const { linhaSelecionada, handleShowPDF } = useTriangulacao();

    const infos = [];

    infos.push(linhaSelecionada)

    const customStyles = {
        headCells: { style: { backgroundColor: '#ffffff', justifyContent: 'center', color: '#030302' } },
        cells: { style: { justifyContent: 'center' } },
        rows: { style: { cursor: 'auto' } }
    }

    const rowColumns = [
        { name: 'STATUS', selector: row => row.DescricaoStatusFinal, atr: 'DescricaoStatusFinal', width: '183px' },
        {
            cell: row => {
              return (
                row.ArquivoLaudo ?
                <span style={{cursor: 'pointer'}}><MaterialIcon icon='description' color='#0D6EFD' size={30} onClick={() => handleShowPDF(row.ArquivoLaudo)}/></span>                
                : null
              )
          }, name: 'LAUDO'
        },
        { name: 'PEDIDO', selector: row => row.Pedido, atr: 'Pedido', },
        { name: 'MATERIAL', selector: row => row.Material, atr: 'Material', },
        { name: 'COR', selector: row => row.CorMaterial, atr: 'CorMaterial', },
        { name: 'FORNECEDOR', selector: row => row.Fornecedor, atr: 'Fornecedor', }
    ];

    return (
        <section style={{ margin: '20px 20px 30px' }}>
            <Tabela
                columns={rowColumns}
                data={infos}
                customStylesOverrider={customStyles}
                pagination={false}
            />
        </section>
    );
};

export default TableInfos;
