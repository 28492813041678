import React from "react";
import { useState, useEffect } from "react";
import { toast, ToastContainer } from 'react-toastify';

import { api, retryApi } from "../services/api";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";

import Filter from '../components/RevisaoMP/Filter';
import TableComponent from "../components/RevisaoMP/Table";
import ModalRevisao from '../components/RevisaoMP/ModalRevisao';

import styles from '../styles/Revisao.module.scss';
import useChange from "../hooks/useChange";
import DadosDoPedido from "../components/RevisaoMP/DadosPedido";
import CookiesService from "../services/cookies";

const DadosPedido = (props) => {

  const [pecasResource, setPecasResource] = useState([])

  const [peca, setPeca] = useState(-1)

  const [peso, setPeso] = useState('')

  const [metroForn, setMetroForn] = useState('')

  const [largForn, setLargForn] = useState('')
  const [largAferida, setLargAferida] = useState('')

  const [ourela, setOurela] = useState('')

  const [relogio, setRelogio] = useState('')

  const pesoHandler = useChange(setPeso, [(v) => v.replace(/\,/g, '.')]);
  const metroFornHandler = useChange(setMetroForn, [(v) => v.replace(/\,/g, '.')]);
  const largFornHandler = useChange(setLargForn, [(v) => v.replace(/\,/g, '.')]);
  const largAferidaHandler = useChange(setLargAferida, [(v) => v.replace(/\,/g, '.')]);
  const ourelaHandler = useChange(setOurela, [(v) => v.replace(/\,/g, '.')]);
  const relogioHandler = useChange(setRelogio, [(v) => v.replace(/\,/g, '.')]);

  async function addPeca() {

    if (
      parseInt(peca) === -1 ||
      !peso ||
      !metroForn ||
      !largForn ||
      !largAferida ||
      !ourela ||
      !relogio
    ) {
      toast.warning("Por favor preencha todos os dados da peça!");
      return;
    }

    const p = {
      peca,
      peso,
      metroForn,
      largForn,
      largAferida,
      ourela,
      relogio
    }

    setPeca(-1);
    setPeso('')
    setMetroForn('')
    setLargForn('')
    setLargAferida('')
    setOurela('')
    setRelogio('')
    props.addHandler(p);
  }

  const ConsultaPecas = async (obj) => {
    try {
      const { data } = await api.post(`/mp-revisao/consulta-pecas`, {
        nfentrada: obj.nfentrada,
        serienf: obj.serienf,
        nomeclifor: obj.nomeclifor,
        material: obj.material,
        cormaterial: obj.cormaterial,
        item: obj.item
      });

      const ps = data.map(v => {
        return Object.values(v)[0];
      });
      setPecasResource(ps);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    ConsultaPecas(props.obj);
  }, [props.obj])

  return (
    <div className={styles.divFormRevisaoAndamento}>
      <Form className={styles.formRevisaoAndamento}>
        <Form.Group className={styles.formGroupRevisaoAndamento}>
          <Form.Label>Localização:</Form.Label>
          <Form.Label className={styles.fontRedBold}>{props.obj.PossuiAmostra}</Form.Label>
        </Form.Group>
        <Form.Group className={styles.formGroupRevisaoAndamento}>
          <Form.Label>Enviado Aprovação:</Form.Label>
          <Form.Label className={styles.fontBlueBold}>{props.obj.EnviadoAprovacao}</Form.Label>
        </Form.Group>
        <Form.Group className={styles.formGroupRevisaoAndamento}>
          <Form.Label>TT Peças:</Form.Label>
          <Form.Label className={styles.fontBold}>{props.obj.PECA}</Form.Label>
        </Form.Group>
        <Form.Group className={styles.formGroupRevisaoAndamento}>
          <Form.Label>Faltam:</Form.Label>
          <Form.Label className={styles.fontBold}>{props.obj.PECA - props.pecasAnalisadas.length}</Form.Label>
        </Form.Group>
        <Form.Group className={styles.formGroupRevisaoAndamento}>
          <Form.Label>Peça:</Form.Label>
          <Form.Select value={peca} onChange={(e) => setPeca(e.target.value)}>
            <option value={-1}>Selecione...</option>
            {
              pecasResource.map(p => {
                if (props.pecasAnalisadas.map(p => {
                  return p.peca;
                }).indexOf(p) === -1) {
                  return <option key={p} value={p}>{p}</option>;
                }
              }).filter(p => {
                return !!p;
              })
            }
          </Form.Select>
        </Form.Group>
        <Form.Group className={styles.formGroupRevisaoAndamento}>
          <Form.Label>Peso:</Form.Label>
          <Form.Control value={peso} onChange={pesoHandler} />
        </Form.Group>
        <Form.Group className={styles.formGroupRevisaoAndamento}>
          <Form.Label>Metro Fornec.</Form.Label>
          <Form.Control value={metroForn} onChange={metroFornHandler} />
        </Form.Group>
      </Form>

      <Form className={styles.formRevisaoAndamento}>
        <Form.Group className={styles.formGroupRevisaoAndamento}>
          <Form.Label>Larg. Fornec.</Form.Label>
          <Form.Control value={largForn} onChange={largFornHandler} />
        </Form.Group>
        <Form.Group className={styles.formGroupRevisaoAndamento}>
          <Form.Label>Larg. Aferida:</Form.Label>
          <Form.Control value={largAferida} onChange={largAferidaHandler} />
        </Form.Group>
        <Form.Group className={styles.formGroupRevisaoAndamento}>
          <Form.Label>Ourela:</Form.Label>
          <Form.Control value={ourela} onChange={ourelaHandler} />
        </Form.Group>
        <Form.Group className={styles.formGroupRevisaoAndamento}>
          <Form.Label>Relógio:</Form.Label>
          <Form.Control value={relogio} onChange={relogioHandler} />
        </Form.Group>
        <Button variant="primary" className={styles.formBtnRevisaoAndamento} onClick={addPeca}>Adicionar</Button>

      </Form>

      {/* 
      <Form>
        <div>
          <div>
            <Form.Label>Localização:</Form.Label>
            <Form.Label className={styles.fontRedBold}>{props.obj.PossuiAmostra}</Form.Label>
          </div>
          <div>
            <Form.Label>Enviado Aprovação:</Form.Label>
            <Form.Label className={styles.fontBlueBold}>{props.obj.EnviadoAprovacao}</Form.Label>
          </div>
          <div>
            <div>
              <Form.Label>TT Peças:</Form.Label>
              <Form.Label className={styles.fontBold}>{props.obj.PECA}</Form.Label>
            </div>
            <div>
              <Form.Label>Faltam:</Form.Label>
              <Form.Label className={styles.fontBold}>{props.obj.PECA - props.pecasAnalisadas.length}</Form.Label>
            </div>
          </div>
          <div>
            <Form.Label>Peça:</Form.Label>
            <Form.Select value={peca} onChange={(e) => setPeca(e.target.value)}>
              <option value={-1}>Selecione...</option>
              {
                pecasResource.map(p => {
                  if(props.pecasAnalisadas.map(p => {
                    return p.peca;
                  }).indexOf(p) === -1){
                    return <option key={p} value={p}>{p}</option>;
                  }
                }).filter(p => {
                  return !!p;
                })
              }
            </Form.Select>
          </div>
          <div>
            <Form.Label>Peso:</Form.Label>
            <Form.Control value={peso} onChange={(e) => setPeso(e.target.value)}/>
          </div>
          <div>
            <Form.Label>Metro Fornec.</Form.Label>
            <Form.Control value={metroForn} onChange={(e) => setMetroForn(e.target.value)}/>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between mb-3">
          <div>
            <Form.Label>Larg. Fornec.</Form.Label>
            <Form.Control value={largForn} onChange={(e) => setLargForn(e.target.value)}/>
          </div>
          <div>
            <Form.Label>Larg. Aferida:</Form.Label>
            <Form.Control value={largAferida} onChange={(e) => setLargAferida(e.target.value)}/>
          </div>
          <div>
            <Form.Label>Ourela:</Form.Label>
            <Form.Control value={ourela} onChange={(e) => setOurela(e.target.value)}/>
          </div>
          <div>
            <Form.Label>Relógio:</Form.Label>
            <Form.Control value={relogio} onChange={(e) => setRelogio(e.target.value)}/>
          </div>
          <div className="d-flex col-2 flex-column pe-3 ps-3 opacity-0">
            <Form.Label>Peso:</Form.Label>
            <Form.Control/>
          </div>
          <div>
            <Form.Label className="opacity-0">Peso:</Form.Label>
            <Button variant="primary" className="shadow-none" onClick={addPeca}>Iniciar Análise</Button>
          </div>
        </div>
      </Form>
      */}
    </div>
  )
}

const DefeitoModal = (props) => {

  const [defeito, setDefeito] = useState(-1);
  const [local, setLocal] = useState(-1);
  const [tamanho, setTamanho] = useState(-1);
  const [condenar, setCondenar] = useState(false);
  const [perda, setPerda] = useState(false);
  const [qntPerda, setQntPerda] = useState('');
  const [consideracao, setConsideracao] = useState('');

  function Close() {
    props.closeHandler();

    setDefeito(-1);
    setLocal(-1);
    setTamanho(-1);
    setCondenar(false);
    setPerda(false);
    setQntPerda('');
    setConsideracao('');
  }


  useEffect(() => {
    setLocal(-1);
    setTamanho(-1);
  }, [defeito])

  useEffect(() => {
    setTamanho(-1);
  }, [local])

  function addDefeito() {

    let valid = true;

    valid = valid && (parseInt(defeito) !== -1);
    valid = valid && (parseInt(local) !== -1);
    valid = valid && (parseInt(tamanho) !== -1);

    let qntP = !!parseInt(qntPerda) ? qntPerda : 0;

    if (valid) {
      props.addHandler({
        defeito: props.dfs[defeito].replace(/[0-9]{2} - /, '').trim(),
        defeito_id: props.dfs[defeito].slice(0, 2),
        local: props.lcs[local].replace(/[0-9]{2} - /, '').trim(),
        local_id: props.lcs[local].slice(0, 2),
        tamanho: props.tms[tamanho].replace(/([0-9]{2} - DE:)|([0-9]{2} - )/, '').trim(),
        tamanho_id: props.tms[tamanho].slice(0, 2),
        condenar,
        pontuacao: parseInt(props.tms[tamanho].slice(0, 2).slice(0, 2)),
        perda,
        qntPerda: perda ? qntP : 0,
        consideracao
      });
      Close();
    }
  }

  return (
    <Modal show={props.show}>
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title>Adicionar Defeito</Modal.Title>
        <Button variant="dark" className="shadow-none" onClick={Close}>X</Button>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column">
        <div className="d-flex justify-content-between w-100 pe-3 ps-3 mt-3">
          <div className="w-50 pe-3">
            <Form.Label>Defeito:</Form.Label>
            <Form.Select value={defeito} onChange={(e) => setDefeito(e.target.value)}>
              <option value={-1}>Selecione...</option>
              {
                props.dfs.map((d, i) => {
                  return <option key={d.slice(0, 2)} value={i}>{d}</option>
                })
              }
            </Form.Select>
          </div>
          <div className="w-50 ps-3">
            <Form.Label>Consideração:</Form.Label>
            <Form.Control value={consideracao} onChange={(e) => setConsideracao(e.target.value)} />
          </div>
        </div>
        <div className={"d-flex justify-content-between w-100 pe-3 ps-3 mt-3"}>
          <div className="w-50 pe-3">
            <Form.Label>Localização:</Form.Label>
            <Form.Select value={local} onChange={(e) => setLocal(e.target.value)} disabled={parseInt(defeito) === -1}>
              <option value={-1}>Selecione...</option>
              {
                props.lcs.map((l, i) => {
                  return <option key={l.slice(0, 2)} value={i}>{l}</option>
                })
              }
            </Form.Select>
          </div>
          <div className="w-50 ps-3 d-flex mt-3">
            <div className="pe-3">
              <Form.Label>Condenar Peça?</Form.Label>
              <Form.Check value={condenar} onChange={e => setCondenar(e.target.checked)} />
            </div>
            <div className="ps-3">
              <Form.Label>Gerou Perda?</Form.Label>
              <Form.Check value={perda} onChange={e => setPerda(e.target.checked)} />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between w-100 pe-3 ps-3 mt-3">
          <div className="w-50 pe-3">
            <Form.Label>Tamanho:</Form.Label>
            <Form.Select value={tamanho} onChange={(e) => setTamanho(e.target.value)} disabled={(parseInt(defeito) === -1) || (parseInt(local) === -1)}>
              <option value={-1}>Selecione...</option>
              {
                props.tms.map((t, i) => {
                  return <option key={t.slice(0, 2)} value={i}>{t}</option>
                })
              }
            </Form.Select>
          </div>
          <div className="w-50 ps-3">
            <Form.Label>Qnt. Perda:</Form.Label>
            <Form.Control value={qntPerda} onChange={e => setQntPerda(e.target.value)} disabled={!perda} />
          </div>
        </div>
        <div className="d-flex justify-content-center w-100 pe-3 ps-3 mt-3">
          <Button className="shadow-none" onClick={addDefeito}>Adicionar</Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const Revisao = () => {

  const [pecas, setPecas] = useState([]);
  const [pecaSelecionada, setPecaSelecionada] = useState(-1);


  const [defeitos, setDefeitos] = useState({});
  const [defeitoSelecionado, setDefeitoSelecionado] = useState(-1);

  const [addingDefeito, setAddingDefeito] = useState(false);

  const [loaded, setLoaded] = useState(false);

  const [user, setUser] = useState('');

  const [page, setPage] = useState(1);
  const [pageArray, setPageArray] = useState([]);

  const [resList, setResList] = useState([]);
  const [list, setList] = useState([]);

  const [revisando, setRevisando] = useState(false);

  const [finalizando, setFinalizando] = useState(false);

  const [amostra, setAmostra] = useState();

  const [ap, setAp] = useState('');

  const [OpcoesFinal, setOpcoesFinal] = useState(['Aprovado', 'Aprovado Cartela', 'Pendenciar', 'Reprovado']);
  const [OpcoesFinalSel, setOpFinalSel] = useState(-1);

  const [consideracao, setConsideracao] = useState('');
  const [numLacre, setNumLacre] = useState('');

  const [motivoRecusaOps, setMotivoRecusaOps] = useState([]);
  const [motivoRecusa, setMotivoRecusa] = useState(-1);

  const [valEstilo, setValEstilo] = useState(false);
  const [pendLab, setPendLab] = useState(-1);
  const [envPlotter, setEnvPlotter] = useState(false);

  const [resDefeitos, setResDefeitos] = useState([]);
  const [resLocais, setResLocais] = useState([]);
  const [resTamanhos, setResTamanhos] = useState([]);

  useEffect(() => {
    if (!loaded) {
      const usr = CookiesService.getUserLogin();
      setLoaded(true);
      setUser(usr);
      LoadResources(usr);
    }

    const pg = Array.from('0'.repeat(Math.ceil(list.length / 50)));
    setPageArray(pg);
  }, [list, page]);

  async function Finalizar() {
    try {
      if (!parseInt(amostra.EnvioPlotter) === 1 && !amostra.Mostruario === 0) {
        toast.warning("É obrigatório marcar o envio do plotter!");
        return;
      }

      // eslint-disable-next-line no-mixed-operators
      if (envPlotter && (parseInt(amostra.EnvioPlotter) === 0 || !parseInt(amostra.EnvioPlotter) === 1 && !amostra.Mostruario === 0)) {
        toast.warning("É obrigatório desmarcar o envio do plotter!");
        return;
      }


      if (parseInt(pendLab) === -1) {
        toast.warning("A `Pendenciar Laboratório` não pode ficar em branco!");
        return;
      }

      if (parseInt(OpcoesFinalSel) === -1) {
        toast.warning("Selecione o status!");
        return;
      }

      if (parseInt(motivoRecusa) === -1 && parseInt(OpcoesFinalSel) === 3) {
        toast.warning("Selecione o motivo da recusa!");
        return;
      }

      if (envPlotter && numLacre === '') {
        toast.warning("Por favor, preencha o numero do lacre!");
        return;
      }

      if (!window.confirm("Esta ação irá finalizar a avalizaão, deseja confirmar?")) {
        return;
      }

      if (!envPlotter && enviarPlotter()) {
        toast.info("Voce deve marcar check de envio plotter")
        return;
      }

      const f = {
        status: ap,
        amostra,
        pecas,
        defeitos,
        aprovacao: OpcoesFinal[OpcoesFinalSel],
        aprovacaoNum: OpcoesFinalSel,
        consideracao,
        motivo_recusa: parseInt(motivoRecusa) !== -1 ? motivoRecusaOps[motivoRecusa].slice(0, 2) : null,
        validar_estilo: valEstilo,
        pendenciar_laboratorio: parseInt(pendLab) === 1,
        enviar_plotter: envPlotter,
        numero_lacre: numLacre,
        user
      }

      await api.post('/mp-revisao/finalizar', f);
      toast.success("Revisão Finalizada!");
      setTimeout(() => { window.location.reload() }, 3000);
    } catch (e) {
      toast.warning("Houve um erro ao finalizar a revisão!");
      console.error(e);
    }
  }

  function prosseguir() {
    if (amostra.PECA - pecas.length > 0) {
      toast.warning("Faltam peças a serem analisadas. Por favor, analise todas as peças!");
      return;
    }

    let status = '';

    pecas.forEach(peca => {
      let pontos = 0;
      defeitos[peca.peca].forEach(defeito => {
        defeito.peca = peca.peca;
        pontos += defeito.condenar ? 9999 : defeito.pontuacao;
      });

      const d = parseFloat(peca.largAferida) * parseFloat(peca.relogio);
      const porcentagem = Math.round((pontos * 100) / d * 100) / 100;

      peca.aprovado = porcentagem <= 35;

      if (parseFloat(peca.largForn) - parseFloat(peca.largAferida) >= (parseFloat(peca.largForn) * (amostra.descricaomaterial.includes('CREPE ZC5811') ? 0.05 : 0.03))) {
        status = "Reprovado Largura";
      } else if (parseFloat(peca.largAferida) - parseFloat(peca.largForn) >= (parseFloat(peca.largAferida) * 0.05)) {
        status = "Reprovado Largura";
      }
      else if ((parseFloat(peca.metroForn) - parseFloat(peca.relogio) >= parseFloat(peca.metroForn) * 0.02)) {
        status = "Reprovado Metragem";
      }
    });

    const aprovadas = pecas.reduce((count, peca) => count + (peca.aprovado ? 1 : 0), 0);
    const percentApr = (aprovadas / pecas.length) * 100;

    if (percentApr < 70 || aprovadas === 0) {
      status = "Reprovado";
    } else if (!status.match(/Reprovado/ig)) {
      status = "Aprovado";
    }

    const totalDefeitos = Object.values(defeitos).reduce((arr, value) => {
      return value ? arr.concat(value) : arr;
    }, []);

    setDefeitos(totalDefeitos);
    setAp(status);
    setFinalizando(true);
  }

  function addPeca(p) {
    const ps = [...pecas, p];

    const ds = { ...defeitos };
    ds[p.peca] = [];

    setDefeitos(ds);
    setPecaSelecionada(p.peca);
    setPecas(ps);
  }

  function OpenDefeitoModal() {
    if (pecaSelecionada !== -1) {
      setAddingDefeito(true);
    } else {
      toast.warning("Selecione uma peça para adicionar um defeito!")
    }
  }

  function addDefeito(d) {
    const ds = [...defeitos[pecaSelecionada], d];
    defeitos[pecaSelecionada] = ds;
    setDefeitos(defeitos);
  }

  function removerDefeito() {
    const ds = [...defeitos[pecaSelecionada]];

    ds.splice(defeitoSelecionado, 1);
    defeitos[pecaSelecionada] = ds;

    setDefeitos(defeitos);
    setDefeitoSelecionado(-1)
  }

  function RemoverPeca() {
    const ps = [...pecas].filter(v => {
      return v.peca !== pecaSelecionada;
    });

    const ds = { ...defeitos };

    ds[pecaSelecionada] = undefined;

    setPecaSelecionada(-1)
    setDefeitos(ds);
    setPecas(ps);
  }

  async function OpenInicioRevisao(i) {
    try {
      let pedido = list[i];
      const { data } = await retryApi('get', `/mp-revisao/buscar-amostra?material=${pedido.material}&cormaterial=${pedido.cormaterial}`);
      const estq = pedido.PossuiAmostra === "SEM AMOSTRA" ? "SIM" : "NÃO";

      let amstr = {
        ...pedido,
        estoque: estq,
        peca: data.PECA,
        local: data.LOCALIZACAO,
        qtde_amostra: data.QTDE
      }

      setAmostra(amstr);
    } catch (e) {
      console.error(e);
    }
  }

  async function CloseInicioRevisao() {
    setAmostra(null)
  }

  async function LoadResources(usr) {
    try {
      let data;
      let revisao_iniciada = false;
      const r1 = await retryApi('get', `/mp-revisao/buscar?usuario=${usr}&qtdeProcessos=1`);
      const d1 = await retryApi('get', `/mp-revisao/defeitos`);
      const d2 = await retryApi('get', `/mp-revisao/local`);
      const d3 = await retryApi('get', `/mp-revisao/tamanho`);
      const mr = await retryApi('get', `/mp-revisao/motivos`);

      if (r1.data.length > 0) {
        revisao_iniciada = true;
        data = r1.data;
      } else {
        const r2 = await retryApi('get', `/mp-revisao/buscar?usuario=${usr}`);
        data = r2.data;
      }

      setMotivoRecusaOps(mr.data);
      setResList(data);
      setList(data);
      setResDefeitos(d1.data);
      setResLocais(d2.data);
      setResTamanhos(d3.data);

      if (revisao_iniciada) {
        const pedido = data[0];
        const a = await retryApi('get', `/mp-revisao/buscar-amostra?material=${pedido.material}&cormaterial=${pedido.cormaterial}`);

        const estq = pedido.PossuiAmostra === "SEM AMOSTRA" ? "SIM" : "NÃO";

        let amstr = {
          ...pedido,
          estoque: estq,
          peca: a.data.PECA,
          local: a.data.LOCALIZACAO,
          qtde_amostra: a.data.QTDE
        }

        setAmostra(amstr)
        setRevisando(true);
      }
    } catch (e) {
      console.error(e);
    }
  }

  function FilterHandler(obj) {
    const keys = Object.keys(obj);

    const arr = resList.filter(v => {

      let pass = true;

      for (let i = 0; i < keys.length; i++) {
        if (obj[keys[i]]) {
          pass = (pass && v[keys[i]].trim() === obj[keys[i]].trim());
        }
      }

      setPage(1);

      return pass;
    });

    setList(arr);
  }

  async function StartRevisando(amstr) {
    try {
      if (!(amstr.nfentrada)) {
        amstr = amostra;
      }
      await api.post(`/mp-revisao/iniciar`, {
        usuario: user,
        nfentrada: amstr.nfentrada,
        serienf: amstr.serieNF,
        nomeclifor: amstr.nomeCliFor,
        material: amstr.material,
        cormaterial: amstr.cormaterial,
        qtde_entrada: amstr.QtdeEntrada
      });
      setRevisando(true);
    } catch (e) {
      toast.error('Erro ao iniciar revisão - Por favor tente novamente');
    }
  }

  function avaliarPlotter() {
    return amostra && !!parseInt(amostra.EnvioPlotter) ? "SIM" : "NÃO"
  }

  function enviarPlotter() {
    if (avaliarPlotter() === 'SIM' && amostra.Mostruario !== 1) return true
    return false
  }

  if (!revisando) {
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />

        <ModalRevisao obj={!!amostra ? amostra : {}} closeHandler={CloseInicioRevisao} starthandler={StartRevisando} show={!!amostra} />

        <article className={styles.articleMargin}>
          <h3>Revisão</h3>
          <Filter filterHandler={FilterHandler} />
        </article>

        <article className={styles.articleGrayPageOne}>
          <TableComponent starthandler={OpenInicioRevisao} page={page} rows={list} />
        </article>

        <article className={styles.formPageSelect}>
          <Form className={styles.articleMarginNumberPage}>
            <Form.Group className="d-flex ms-auto" width={50}>
              <Form.Label className="mt-2 me-3">Página</Form.Label>
              <Form.Select value={page} onChange={e => setPage(e.target.value)}>
                {
                  pageArray.length > 0 ?
                    pageArray.map((v, i) => {
                      return <option key={i} value={i + 1}>{i + 1}</option>
                    }) :
                    <option key={1} value={1}>{1}</option>
                }
              </Form.Select>
            </Form.Group>
          </Form>
        </article>
      </div>
    );
  } else if (!finalizando) {
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
        <DefeitoModal dfs={resDefeitos} lcs={resLocais} tms={resTamanhos} show={addingDefeito} closeHandler={() => setAddingDefeito(false)} addHandler={addDefeito} />

        <DadosDoPedido obj={!!amostra ? amostra : {}} />

        <article className={styles.articleMargin}>
          <h3>Revisão: Em Andamento</h3>
          <DadosPedido addHandler={addPeca} pecasAnalisadas={pecas} obj={amostra} />
        </article>

        <article className={styles.articleMargin}>
          <div className={styles.tablesColumm}>
            <div className="">
              <div className={styles.alignRowSpaceBtw}>
                <Form.Label className="ps-2 mb-0 mt-2">Peças:</Form.Label>
                <div className="">
                  <Button className="shadow-none opacity-0" disabled>Adicionar</Button>
                  <Button variant="danger" className="shadow-none ms-3" onClick={RemoverPeca} disabled={parseInt(pecaSelecionada) === -1}>Remover</Button>
                </div>
              </div>
              <div className={styles.tableGrayMargin}>
                <Table className="m-2" responsive>
                  <thead>
                    <tr>
                      <th className={styles.blockTitle}>Peça</th>
                      <th className={styles.blockTitle}>Peso</th>
                      <th className={styles.blockTitle}>Larg. Útil</th>
                      <th className={styles.blockTitle}>Larg. Forneceor</th>
                      <th className={styles.blockTitle}>Metr. Relógio</th>
                      <th className={styles.blockTitle}>% Dif. Largura</th>
                      <th className={styles.blockTitle}>Possui Defeito</th>
                      <th className={styles.blockTitle}>Metragem For.</th>
                      <th className={styles.blockTitle}>% Dif. Metragem</th>
                      <th className={styles.blockTitle}>Medida Ourela</th>
                      <th className={styles.blockTitle}>Perda da Peça</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      pecas.map(p => {
                        return (
                          <tr key={p.peca} className={"clickable " + (pecaSelecionada === p.peca ? "bg-primary text-light" : "")}
                            onClick={() => {
                              if (p.peca === pecaSelecionada) {
                                setPecaSelecionada(-1);
                              } else {
                                setPecaSelecionada(p.peca);
                              }
                            }}>
                            <td className={styles.tableLines}>{p.peca}</td>
                            <td className={styles.tableLines}>{p.peso}</td>
                            <td className={styles.tableLines}>{p.largAferida}</td>
                            <td className={styles.tableLines}>{p.largForn}</td>
                            <td className={styles.tableLines}>{p.relogio}</td>
                            <td className={styles.tableLines}>{`${Math.abs(((parseFloat(p.largAferida) - parseFloat(p.largForn)) / parseFloat(p.largForn)) * 100).toFixed(2)} %`}</td>
                            <td className={styles.tableLines}>{defeitos[p.peca].length > 0 ? "SIM" : "NÃO"} </td>
                            <td className={styles.tableLines}>{p.metroForn}</td>
                            <td className={styles.tableLines}>{`${Math.abs(Math.round(((1 - (parseFloat(p.relogio) / parseFloat(p.metroForn))) * parseFloat(p.metroForn)) * 100) / 100)} %`}</td>
                            <td className={styles.tableLines}>{p.ourela}</td>
                            <td className={styles.tableLines}>
                              {
                                defeitos[p.peca].length > 0 ?
                                  defeitos[p.peca].reduce((acc, v) => {
                                    return acc + parseInt(v['perda'] ? v['qntPerda'] : 0);
                                  }, 0) :
                                  0
                              }
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>
              </div>
            </div>

            <div className="">
              <div className={styles.alignRowSpaceBtw}>
                <Form.Label className="ps-2 mb-0 mt-2">Defeitos:</Form.Label>
                <div>
                  <Button className="shadow-none" onClick={OpenDefeitoModal} disabled={parseInt(pecaSelecionada) === -1}>Iniciar Análise</Button>
                  <Button className="shadow-none btn-danger ms-3" onClick={removerDefeito} disabled={parseInt(defeitoSelecionado) === -1}>Remover</Button>
                </div>
              </div>
              <div className={styles.tableGrayMargin}>
                <Table className="m-2" responsive>
                  <thead>
                    <tr>
                      <th className={styles.blockTitle}>Defeito</th>
                      <th className={styles.blockTitle}>Localização</th>
                      <th className={styles.blockTitle}>Tamanho</th>
                      <th className={styles.blockTitle}>Pontuação</th>
                      <th className={styles.blockTitle}>Perda</th>
                      <th className={styles.blockTitle}>Consideração</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      parseInt(pecaSelecionada) !== -1 ? defeitos[pecaSelecionada].map((d, i) => {
                        return (
                          <tr key={pecaSelecionada + '_' + i} className={"clickable " + (defeitoSelecionado === i ? "bg-primary text-light" : "")}
                            onClick={() => {
                              if (i === defeitoSelecionado) {
                                setDefeitoSelecionado(-1);
                              } else {
                                setDefeitoSelecionado(i);
                              }
                            }}>
                            <td className={styles.tableLines}>{d.defeito}</td>
                            <td className={styles.tableLines}>{d.local}</td>
                            <td className={styles.tableLines}>{d.tamanho}</td>
                            <td className={styles.tableLines}>{d.condenar ? 9999 : d.pontuacao}</td>
                            <td className={styles.tableLines}>{d.perda ? d.qntPerda : 0}</td>
                            <td className={styles.tableLines}>{d.consideracao}</td>
                          </tr>
                        )
                      }) : null
                    }
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </article>

        <footer className={styles.footerRevisaoAndamento}>
          <Button className={styles.btnProsseguirMargin} onClick={prosseguir}>
            Prosseguir
          </Button>
        </footer>
      </div>
    )
  } else {
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />

        <DadosDoPedido obj={!!amostra ? amostra : {}} />

        <h3 className={styles.articleMargin}>
          Revisão: {
            !!ap.match(/Reprovado/ig)
              ? <span className="text-danger">{ap}</span>
              : <span className="text-primary">{ap}</span>
          }
        </h3>

        <h5 className={styles.titleResultRevisao}>
          Peças:
          <span className=" ms-5 text-primary">{pecas.reduce((s, v) => { return !!v.aprovado ? s + 1 : s + 0 }, 0)}</span>/{pecas.length} Aprovadas.
          <span className="ms-5" /> Plano Amostral: <span className="text-primary me-5">{pecas.length}</span>
          Índice de Aprovação: <span className="text-primary">{
            Math.round((pecas.reduce((s, v) => { return !!v.aprovado ? s + 1 : s + 0 }, 0) / pecas.length) * 100)
          }%
          </span>
        </h5>

        <div className={styles.articleMarginTwenty}>
          <article className={styles.tableGrayNoMargin}>
            <Table className="m-2" responsive>
              <thead>
                <tr>
                  <th className={styles.blockTitle}>Peça</th>
                  <th className={styles.blockTitle}>Peso</th>
                  <th className={styles.blockTitle}>Larg. Útil</th>
                  <th className={styles.blockTitle}>Larg. Forneceor</th>
                  <th className={styles.blockTitle}>Metr. Relógio</th>
                  <th className={styles.blockTitle}>% Dif. Largura</th>
                  <th className={styles.blockTitle}>Possui Defeito</th>
                  <th className={styles.blockTitle}>Metragem For.</th>
                  <th className={styles.blockTitle}>% Dif. Metragem</th>
                  <th className={styles.blockTitle}>Medida Ourela</th>
                  <th className={styles.blockTitle}>Perda da Peça</th>
                </tr>
              </thead>
              <tbody>
                {
                  pecas.map(p => {
                    return (
                      <tr key={p.peca}>
                        <td className={styles.tableLines}>{p.peca}</td>
                        <td className={styles.tableLines}>{p.peso}</td>
                        <td className={styles.tableLines}>{p.largAferida}</td>
                        <td className={styles.tableLines}>{p.largForn}</td>
                        <td className={styles.tableLines}>{p.relogio}</td>
                        <td className={styles.tableLines}>
                          {`${Math.abs(Math.round(((1 - (parseFloat(p.largAferida) / parseFloat(p.largForn))) * parseFloat(p.largForn)) * 100) / 100)} %`}
                        </td>
                        <td className={styles.tableLines}>
                          {defeitos.filter(v => {
                            return v['peca'] === p.peca;
                          }).length > 0 ? "SIM" : "NÃO"}
                        </td>
                        <td className={styles.tableLines}>{p.metroForn}</td>
                        <td className={styles.tableLines}>
                          {`${Math.abs(Math.round(((1 - (parseFloat(p.relogio) / parseFloat(p.metroForn))) * parseFloat(p.metroForn)) * 100) / 100)} %`}
                        </td>
                        <td className={styles.tableLines}>{p.ourela}</td>
                        <td className={styles.tableLines}>
                          {
                            defeitos.reduce((acc, v) => {
                              return v['peca'] == p.peca ? (acc + parseInt(v['perda'] ? v['qntPerda'] : 0)) : acc;
                            }, 0)
                          }
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </article>

          <h5 className="mt-5">Defeitos:</h5>

          <article className={styles.tableGrayNoMargin}>
            <Table className="m-2" responsive>
              <thead>
                <tr>
                  <th className={styles.blockTitle}>Peca</th>
                  <th className={styles.blockTitle}>Defeito</th>
                  <th className={styles.blockTitle}>Localização</th>
                  <th className={styles.blockTitle}>Tamanho</th>
                  <th className={styles.blockTitle}>Pontuação</th>
                  <th className={styles.blockTitle}>Perda</th>
                  <th className={styles.blockTitle}>Consideração</th>
                </tr>
              </thead>
              <tbody>
                {
                  defeitos.map((d, i) => {
                    return (
                      <tr key={d.peca + '_' + i} >
                        <td className={styles.tableLines}>{d.peca}</td>
                        <td className={styles.tableLines}>{d.defeito}</td>
                        <td className={styles.tableLines}>{d.local}</td>
                        <td className={styles.tableLines}>{d.tamanho}</td>
                        <td className={styles.tableLines}>{d.condenar ? 9999 : d.pontuacao}</td>
                        <td className={styles.tableLines}>{d.perda ? d.qntPerda : 0}</td>
                        <td className={styles.tableLines}>{d.consideracao}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </article>

          <article className={styles.articleValidaRevisao}>
            <div className="mb-3 d-flex flex-row">
              <h5>
                Tipo Compra:  <span className="text-danger">{amostra.tipocompra}</span>
              </h5>

              <h5 className="ms-4">
                Filial:  <span className="text-danger">{amostra.filial}</span>
              </h5>

              <h5 className="ms-4">
                Enviar Plotter:  <span className="text-danger">{avaliarPlotter()}</span>
              </h5>
            </div>
            <h4 className="mt-2">Validar Padrão</h4>
            <div className="d-flex mt-3">
              <div className="d-flex w-50 justify-content-between ps-3 pe-4">
                <div className="pe-3">
                  <Form.Label>Aprovado</Form.Label>
                  <Form.Check checked={parseInt(OpcoesFinalSel) === 0} onChange={(e) => { setOpFinalSel(0) }} />
                </div>
                <div className="ps-3 pe-3">
                  <Form.Label>Aprovado Cartela</Form.Label>
                  <Form.Check checked={parseInt(OpcoesFinalSel) === 1} onChange={(e) => { setOpFinalSel(1) }} />
                </div>
                <div className="ps-3 pe-3">
                  <Form.Label>Pendenciar</Form.Label>
                  <Form.Check checked={parseInt(OpcoesFinalSel) === 2} onChange={(e) => { setOpFinalSel(2) }} />
                </div>
                <div className="ps-3 pe-3">
                  <Form.Label>Reprovado</Form.Label>
                  <Form.Check checked={parseInt(OpcoesFinalSel) === 3} onChange={(e) => { setOpFinalSel(3) }} />
                </div>
              </div>
              <div className="d-flex w-50 ps-4 pe-3">
                <div className="w-50">
                  <Form.Label>Motivo Recusa:</Form.Label>
                  <Form.Select onChange={(e) => setMotivoRecusa(e.target.value)} disabled={!(parseInt(OpcoesFinalSel) === 3)}>
                    <option value={-1}>Selecione uma opção...</option>
                    {
                      motivoRecusaOps.map((v, i) => {
                        return <option value={i} key={v.slice(0, 2)}>{v}</option>
                      })
                    }
                  </Form.Select>
                </div>
                <div className="w-50 ps-5">
                  <Form.Label>Enviar Validação estilo?</Form.Label>
                  <Form.Switch onChange={(e) => setValEstilo(e.target.checked)} />
                </div>
              </div>
            </div>

            <div className={"d-flex mt-3"}>
              <div className="d-flex w-50 justify-content-between ps-3 pe-4">
                <div className="w-50 pe-3">
                  <Form.Label>Considerações:</Form.Label>
                  <Form.Control onChange={(e) => setConsideracao(e.target.value)} />
                </div>
                <div className="w-50 ps-3">
                  <Form.Label>Numero Lacre Envio Amostra:</Form.Label>
                  <Form.Control onChange={(e) => setNumLacre(e.target.value)} disabled={!envPlotter} />
                </div>
              </div>
              <div className="d-flex w-50 ps-4 pe-3">
                <div className="w-50">
                  <Form.Label>Enviar Plotter?</Form.Label>
                  <Form.Switch
                    value={avaliarPlotter() === 'SIM' && amostra.Mostruario === 0 ? false : true}
                    disabled={avaliarPlotter() === 'SIM' && amostra.Mostruario === 0 ? false : true}
                    onChange={(e) => setEnvPlotter(e.target.checked)} />
                </div>
                <div className="w-50 ps-5">
                  <Form.Label>Pendenciar Laboratório?</Form.Label>
                  <div className="d-flex">
                    <Form.Check type="radio" checked={pendLab === 0} onChange={(e) => setPendLab(0)} /> Não
                    <Form.Check className="ms-4" type="radio" checked={pendLab === 1} onChange={(e) => setPendLab(1)} /> Sim
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>

        <footer className={styles.footerRevisaoAndamento}>
          <Button
            className={styles.btnProsseguirMargin}
            onClick={Finalizar}
          >
            Finalizar
          </Button>
        </footer>
      </div>
    )
  }
};

export default Revisao;
