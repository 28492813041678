import React from 'react';
import { Button, Modal } from "react-bootstrap";
import styles from '../../styles/Revisao.module.scss';

const ModalPendenciaLaboratorio = (props) => {
  return (
    <Modal show={props.show} centered size='lg'>
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title><span className={styles.modalPendenciaHeader}>Mensagem de aviso</span></Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalPendencia}>
        <article>
          <h5 className="mt-1">
            <span className="text-danger">ATENÇÃO:</span> Você está agendando os testes para o material <span className="text-decoration-underline descMaterial">{props.obj.descricaomaterial}</span>.
          </h5>
          <h5 className="mt-1"><span>Caso a NF seja de mostruário:</span> Apenas os testes de <span>ENCOLHIMENTO</span> e <span >TOQUE</span> são obrigatórios.</h5>
          <h5 className="mt-1"><span>Caso a NF seja de produção:</span> Agende todos os testes regulares, incluindo <span>ENCOLHIMENTO</span> e <span>TOQUE.</span></h5>
        </article>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button className="btn-lg shadow-none" style={{ width: "200px" }} onClick={props.closeHandler} variant="primary">
          OK
        </Button>
      </Modal.Footer>
    </Modal>

  );
};

export default ModalPendenciaLaboratorio;