import React from 'react';
import { Accordion } from 'react-bootstrap';
import styles from '../../../../styles/Corte/ControleEnfesto/DistribuicaoDemanda.module.scss';
import { useAlteracaoDemanda } from '../../../../contexts/Corte/ControleEnfesto/AlteracaoDemandaContext'; 

const DadosAccordion = () => {

    const { linhaSelecionada } = useAlteracaoDemanda();

    return (
        <section>
            <Accordion defaultActiveKey={['0']} alwaysOpen>
                <Accordion.Item eventKey="0">
                    <Accordion.Body className={styles.infoBlockDisplay}>
                        <div className={styles.infoBlockColumm}>
                            <span>Ordem Serviço</span>
                            <span className={styles.fontBlue}>{linhaSelecionada.ordemServico}</span>
                        </div>
                        <div className={styles.infoBlockColumm}>
                            <span>Grade</span>
                            <span className={styles.fontBlue}>{linhaSelecionada.grade}</span>
                        </div>
                        <div className={styles.infoBlockColumm}>
                            <span>Folhas Alocadas</span>
                            <span className={styles.fontBlue}>{linhaSelecionada.folhasAlocadas}</span>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </section>
    );
};

export default DadosAccordion;
