import React, { useEffect, useState } from "react";

import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ModalPendenciaRevisao from "./ModalPendenciaRevisao";

const ModalRevisao = (props) => {
  const [showPendenciaModal, setShowPendenciaModal] = useState(false);

  function hasMaterialPendencia(material) {
    return material === '74280942'
  }

  function ClosePendenciaModal() {
    setShowPendenciaModal(false);
  }


  useEffect(() => {
    setShowPendenciaModal(hasMaterialPendencia(props.obj.material));
  }, [props.obj.material])


  return (
    <>
      {showPendenciaModal && <ModalPendenciaRevisao obj={props.obj} closeHandler={ClosePendenciaModal} show={showPendenciaModal} />}
      {!showPendenciaModal && <Modal show={props.show}>
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title>
            Confirmar Inicio Revisão
          </Modal.Title>
          <Button onClick={props.closeHandler} variant={"primary"} className="shadow-none">
            X
          </Button>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column">
          <Container>
            <div className="d-flex justify-content-center flex-row">
              <div className="w-100 d-flex me-2 flex-column">
                <h5 className="mt-3">Dados da Nota Fiscal:</h5>
                <h6 className="">Pedido: <span className="text-primary">
                  {props.obj.pedido}
                </span></h6>
                <h6 className="mt-1">Empresa: <span className="text-primary">
                  {props.obj.empresa}
                </span></h6>
                <h6 className="mt-1">Nota Fiscal: <span className="text-primary">
                  {props.obj.nfentrada}
                </span></h6>
                <h6 className="mt-1">Série NF: <span className="text-primary">
                  {props.obj.serienf}
                </span></h6>
                <h6 className="mt-1">Material: <span className="text-primary">
                  {props.obj.material}
                </span></h6>
                <h6 className="mt-1">Descrição Material: <span className="text-primary">
                  {props.obj.descricaomaterial}
                </span></h6>
                <h6 className="mt-1">Cor Material: <span className="text-primary">
                  {props.obj.cormaterial}
                </span></h6>
                <h6 className="mt-1">Descrição Cor: <span className="text-primary">
                  {props.obj.descricaocor}
                </span></h6>
                <h6 className="mt-1">Cód. Fornecedor: <span className="text-primary">
                  {props.obj.codigofornecedor}
                </span></h6>
                <h6 className="mt-1">Fornecedor: <span className="text-primary">
                  {props.obj.nomeclifor}
                </span></h6>
                <h6 className="mt-1">Qtde Entrada: <span className="text-primary">
                  {props.obj.QtdeEntrada}
                </span></h6>
                <h6 className="mt-1">Qtde de Peças: <span className="text-primary">
                  {props.obj.QtdePecas}
                </span></h6>
              </div>
              <div className="splitter d-flex bg-light"></div>
              <div className="w-100 d-flex ms-2 flex-column">
                <h5 className="mt-3">Dados da Amostra:</h5>
                <h6 className="">Amostra: <span className="text-danger">
                  {props.obj.PossuiAmostra === "SEM AMOSTRA" ? props.obj.PossuiAmostra : props.obj.PossuiAmostra}
                </span></h6>
                <h6 className="mt-1">Em estoque: <span className="text-primary">
                  {props.obj.estoque}
                </span></h6>
                {
                  !!props.obj.local ? (
                    <div><h6 className="mt-1">Quantidade: <span className="text-primary">
                      {props.obj.qtde_amostra}
                    </span></h6>
                      <h6 className="mt-1">Número da Peça: <span className="text-primary">
                        {props.obj.peca}
                      </span></h6>
                      <h6 className="mt-1">Localização: <span className="text-primary">
                        {props.obj.local}
                      </span></h6></div>
                  ) : null
                }

              </div>
            </div>
            <div className="d-flex justify-content-center flex-row">
              <Button onClick={props.starthandler} variant={"primary"} className="shadow-none mt-4 mb-2">
                Iniciar
              </Button>
            </div>
          </Container>
        </Modal.Body>
      </Modal>}
    </>
  )
}

export default ModalRevisao;