import React from 'react';
import styles from '../../../styles/Corte/ControleEnfesto/AlteracaoDemanda.module.scss';

import FilterComponent from '../../../components/Corte/ControleEnfesto/AlteracaoDemanda/FilterComponent';
import MainTable from '../../../components/Corte/ControleEnfesto/AlteracaoDemanda/MainTable';
import ModalAlteraFolha from '../../../components/Corte/ControleEnfesto/AlteracaoDemanda/ModalAlteraFolha';

const AlteracaoDemanda = () => {
    
    return (
        <div>
            <article className={styles.articleTitle}>
                <h3 className="p-3">Corte - Alteração de Demanda</h3>
            </article>

            <FilterComponent />

            <article className={styles.articleGray}>
                <MainTable/>
                <ModalAlteraFolha />
            </article>


        </div>
    );
};

export default AlteracaoDemanda;
