import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import styles from '../../../styles/MP/Triangulacao.module.scss';
import { useTriangulacao } from '../../../contexts/MP/TriangulacaoContext'; 
import TableInfos from './TableInfos';
import TestesSelect from './TestesSelect';
import TestesInfo from './TestesInfo';

const ModalRow = () => {

    const { atualizaTeste, testesRowData, difTesteGram, difTesteLarg, infoTeste, handleCloseConfirmTeste, showConfirmTeste, handleShowConfirmTeste, finalizaTeste, habilitaConfirmar, setObsFinal, setObsLab, obsFinal, obsLab, handleCloseModal, showModal } = useTriangulacao();
 
    const [toleranciaGram, setToleranciaGram] = useState(false);
    const [toleranciaLarg, setToleranciaLarg] = useState(false);

    useEffect(() => {
        if (difTesteGram && Math.abs(difTesteGram) > 10) {
            setToleranciaGram(true)
        } else setToleranciaGram(false);
        if (difTesteLarg && Math.abs(difTesteLarg) > 3) {
            setToleranciaLarg(true)
        } else setToleranciaLarg(false);
    }, [difTesteGram, difTesteLarg])

    const update = testesRowData && testesRowData.length > 0

    return (
        <>
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                centered
                size='xl'
            >
                <Modal.Body>
                    <article>
                        <TableInfos />
                    </article>

                    <article className={styles.articleModal}>
                        <TestesSelect />
                    </article>

                    <article className={styles.articleModal}>
                        <TestesInfo />
                    </article>

                    <article className={styles.articleModal}>
                        <Form.Group className={styles.formGroup}>
                            <Form.Label className={styles.texAreatLabel}>Observação</Form.Label>
                            <Form.Control
                                as="textarea"
                                className={styles.textAreaControl}
                                onChange={e => setObsLab(e.target.value)}
                                value={obsLab || ''}
                            />
                        </Form.Group>
                        <Form.Group className={styles.formGroup}>
                            <Form.Label className={styles.texAreatLabel}>Observação Final</Form.Label>
                            <Form.Control
                                as="textarea"
                                className={styles.textAreaControl}
                                onChange={e => setObsFinal(e.target.value)}
                                value={obsFinal || ''}
                            />
                        </Form.Group>
                    </article>
                </Modal.Body>
                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="secondary"
                        className={styles.modalBtn}
                        onClick={handleCloseModal}
                    >
                        Voltar
                    </Button>
                    <Button
                        variant="primary"
                        className={styles.modalBtn}
                        onClick={handleShowConfirmTeste}
                        disabled={!habilitaConfirmar}
                    >
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showConfirmTeste}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Registro de Recebimento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { toleranciaGram ? 
                        <div className={styles.divTolerancia}>O teste de <strong className={styles.divTolerancia}>Gramatura</strong> está acima da tolerância permitida.</div>
                        : null
                    }
                    { toleranciaLarg ? 
                        <div className={styles.divTolerancia}>O teste de <strong className={styles.divTolerancia}>Largura</strong> está acima da tolerância permitida.</div> 
                        : null
                    }
                    { toleranciaGram || toleranciaLarg ? 
                        <div>
                            <div>Tem certeza que deseja seguir com os testes acima da tolerância?</div>
                            <div>Você está salvando, no total, <strong>{infoTeste.length}</strong> testes.</div>
                        </div>
                        :
                        <div>
                            Tem certeza que deseja salvar esses <strong>{infoTeste.length}</strong> testes?
                        </div>
                    }

                </Modal.Body>
                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-secondary"
                        className={styles.modalBtn}
                        onClick={handleCloseConfirmTeste}
                    >
                        Não
                    </Button>
                    <Button
                        variant="primary"
                        className={styles.modalBtn}
                        onClick={update ? atualizaTeste : finalizaTeste}
                    >
                        Sim
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalRow;
