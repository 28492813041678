import React from 'react';
import styles from '../../styles/MP/Triangulacao.module.scss';
import { Form, Button } from 'react-bootstrap';
import { useTriangulacao } from '../../contexts/MP/TriangulacaoContext';
import MainTable from '../../components/MP/Triangulacao/MainTable'
import ModalRow from '../../components/MP/Triangulacao/ModalRow'
import ModalReceb from '../../components/MP/Triangulacao/ModalReceb';

const Triangulacao = () => {

    const { statusData, filterObj, setFilterObj, fetchTriangulacao } = useTriangulacao();
    
    return (
        <div>
            <article className={styles.articleTitle}>
                <h3 className="p-3">MP - Triangulação</h3>
            </article>

            <article>
                <Form className={styles.formWrapper}>
                    <Form.Group className={styles.filterInput}>
                        <Form.Label className={styles.formLabel}>Pedido</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={'Pedido'}
                            className={styles.formControl}
                            value={filterObj.pedido || ''}
                            onChange={(event) => setFilterObj({ ...filterObj, pedido: event.target.value })}
                        />
                    </Form.Group>
                    <Form.Group className={styles.filterInput}>
                        <Form.Label className={styles.formLabel}>Material</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={'Material'}
                            className={styles.formControl}
                            value={filterObj.material || ''}
                            onChange={(event) => setFilterObj({ ...filterObj, material: event.target.value })}
                        />
                    </Form.Group>
                    <Form.Group className={styles.filterInput}>
                        <Form.Label className={styles.formLabel}>Cor Material</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={'Cor Material'}
                            className={styles.formControl}
                            value={filterObj.cor_material || ''}
                            onChange={(event) => setFilterObj({ ...filterObj, cor_material: event.target.value })}
                        />
                    </Form.Group>
                    <Form.Group className={styles.filterInput}>
                        <Form.Label className={styles.formLabel}>Fornecedor</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={'Fornecedor'}
                            className={styles.formControl}
                            value={filterObj.fornecedor || ''}
                            onChange={(event) => setFilterObj({ ...filterObj, fornecedor: event.target.value })}
                        />
                    </Form.Group>
                    <Form.Group className={styles.filterInput}>
                        <Form.Label className={styles.formLabel}>Status</Form.Label>
                        <Form.Select
                            value={filterObj.status || ''}
                            onChange={(event) => setFilterObj({ ...filterObj, status: event.target.value })}
                            placeholder='Status'
                        >
                            <option disabled value=''>Status</option>
                            {
                                statusData.map(item => {
                                    return (
                                        <option value={item.IdStatus} key={item.IdStatus}>{item.DescricaoStatus}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                    <Button
                        type="button"
                        variant="primary"
                        className={styles.filterBtn}
                        onClick={fetchTriangulacao}
                    >
                        Buscar
                    </Button>
                </Form>
            </article>
            <article className={styles.articleGray}>
                <MainTable />
                <ModalRow />
                <ModalReceb />
            </article>
        </div>
    );
};

export default Triangulacao;
