import { createContext, useEffect, useContext, useState } from 'react';
import { api } from '../../../services/api';
import CookiesService from '../../../services/cookies';
import { toDatetime } from '../../../utils/dateUtils';
import { useMisc } from '../../MiscContext';

const AlteracaoDemandaContext = createContext();
export const useAlteracaoDemanda = () => useContext(AlteracaoDemandaContext);

export const AlteracaoDemandaProvider = ({ children }) => {

  const userLogin = CookiesService.getUserLogin();
  const dataProcesso = toDatetime(new Date());

  const { setShowLoading, setToast } = useMisc();
  const [loading, setLoading] = useState(false);

  const [mainData, setMainData] = useState([]);

  const [filtro, setFiltro] = useState('');

  const [showModal, setShowModal] = useState(false);
  const [showConfirmacao, setShowConfirmacao] = useState(false);
  const [linhaSelecionada, setLinhaSelecionada] = useState({});

  const [qtdeFolhas, setQtdeFolhas] = useState('');

  const handleShowModal = (row) => {
    setLinhaSelecionada(row);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setLinhaSelecionada({});
    setQtdeFolhas('');
    setShowModal(false);
  };

  const handleShowConfirmacao = () => {
    if (qtdeFolhas >= linhaSelecionada.folhasAlocadas) {
      setToast('warn', 'O valor não pode ser igual ou maior à quantidade alocada anteriormente.');
      return
    }
    setShowModal(false);
    setShowConfirmacao(true);
  };

  const handleCloseConfirmacao = () => {
    setShowConfirmacao(false);
    setShowModal(true);
  };

  const handleQtdeFolhas = (e) => {
    const regex = /^\d*$/
    if (!regex.test(e.target.value)){
        return
    }
    const qtde = parseInt(e.target.value)
    setQtdeFolhas(qtde)
  }

  const resetAll = () => {
    setLinhaSelecionada({});
    setQtdeFolhas('');
    setShowConfirmacao(false);
    fetchMainData();
  }

  const fetchMainData = async () => {
    setLoading(true);
    try {
      const res = await api.get('Corte/ControleEnfesto/AlteracaoDemanda/get-rows');
      setMainData(res.data.resPrincipal);
    } catch (e) {
        setToast('error', 'Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre em contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
    } finally {
        setLoading(false);
    }
  };

  const updateFolhas = async () => {
    setShowLoading(true);
    try {
      const res = await api.post('Corte/ControleEnfesto/AlteracaoDemanda/update-folhas', 
          { linhaSelecionada: linhaSelecionada, qtdeFolhas: qtdeFolhas, dataAlteracao: dataProcesso, usuario: userLogin });
      if (res.status === 200) {
        resetAll();
        setToast('success', 'Dados alterados com êxito.')
      } else setToast('error', `Erro, status code: "${res.status}". Entre em contato com o suporte.`);
    } catch (e) {
        setToast('error', 'Ocorreu um erro ao atualizar os dados, por favor cheque sua internet e/ou entre em contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
    } finally {
        setShowLoading(false);
    }
  }

  useEffect(() => {
      fetchMainData();
  }, [])

  return (
    <AlteracaoDemandaContext.Provider
      value={{ handleShowConfirmacao, handleCloseConfirmacao, showConfirmacao, loading, mainData, filtro, setFiltro, handleShowModal, handleCloseModal, showModal, linhaSelecionada, qtdeFolhas, handleQtdeFolhas, updateFolhas }}
    >
      {children}
    </AlteracaoDemandaContext.Provider>
  )
}