import React, { useState } from 'react';
import { Stack, Form, FloatingLabel } from 'react-bootstrap';
import styles from '../../../styles/MP/Triangulacao.module.scss';
import { useTriangulacao } from '../../../contexts/MP/TriangulacaoContext';

const TestesInfo = () => {

    const { setDifTesteGram, setDifTesteLarg, infoTeste, handleInfoTeste, testesSelecionados, indicesData } = useTriangulacao();
    
    const percentOrder = [
        '0.1% a 1%',
        '1.1% a 2%',
        '2.1% a 3%',
        '3.1% a 4%',
        '4.1% a 5%',
        '5.1% a 6%',
        '6.1% a 7%',
        '>7.1%'
    ];

    const tonalidadeOrder = [
        'MOSTRUÁRIO',
        'ORIGINAL',
        'TONALIDADE 1',
        'TONALIDADE 2',
        'TONALIDADE 3',
        'TONALIDADE 4',
        'TONALIDADE 5',
        'TONALIDADE 6',
        'TONALIDADE 7',
        'TONALIDADE 8',
        'TONALIDADE 9',
        'TONALIDADE 10'
    ]

    const sortIndices = (indices) => {
        return indices.sort((a, b) => {
            if (a.Indice.includes('%') && b.Indice.includes('%')) {
                return percentOrder.indexOf(a.Indice) - percentOrder.indexOf(b.Indice);
            }
            if (tonalidadeOrder.includes(a.Indice) && tonalidadeOrder.includes(b.Indice)) {
                return tonalidadeOrder.indexOf(a.Indice) - tonalidadeOrder.indexOf(b.Indice);
            }
            return a.Indice.localeCompare(b.Indice);
        });
    };

    const formatString = (string) => {
        const lowString = string.toLowerCase()
        const firstLetter = lowString.charAt(0).toUpperCase()
        const newString = firstLetter + lowString.slice(1)
        return newString
    }

    return (
        <div className={styles.divStack}>
            {testesSelecionados.map((teste, index) => {
                const indices = sortIndices(indicesData.filter(indice => indice.IdTeste === teste.IdTeste));
                const currentTeste = infoTeste.find(item => item.IdTeste === teste.IdTeste) || {};

                let difTeste;

                if (infoTeste[index].IdTeste === 7 && infoTeste[index].Gramatura && infoTeste[index].GramaturaFornecedor) {
                    difTeste = ((infoTeste[index].Gramatura - infoTeste[index].GramaturaFornecedor) / infoTeste[index].GramaturaFornecedor * 100).toFixed(2);
                    setDifTesteGram(difTeste);
                }
                if (infoTeste[index].IdTeste === 18 && infoTeste[index].Largura && infoTeste[index].LarguraFornecedor) {
                    difTeste = ((infoTeste[index].Largura - infoTeste[index].LarguraFornecedor) / infoTeste[index].LarguraFornecedor * 100).toFixed(2);
                    setDifTesteLarg(difTeste);
                }

                const renderNormalTest = () => (
                    <Stack direction="horizontal" gap={3} key={teste.IdTeste} className={styles.stackTestes}>
                        <div className={styles.divDesc}><em>{teste.DescricaoTeste}</em></div>
                        <div className={styles.divSelect}>
                            <FloatingLabel label='Status'>
                                <Form.Select size='sm'
                                    value={currentTeste.Status || ''}
                                    onChange={e => handleInfoTeste(teste.IdTeste, 'Status', e.target.value)}
                                >
                                    <option disabled value="">Selecione</option>
                                    <option value="Aprovado">Aprovado</option>
                                    <option value="Reprovado">Reprovado</option>
                                </Form.Select>
                            </FloatingLabel>
                        </div>
                        <div className={styles.divSelect}>
                            <FloatingLabel label='Índice'>
                                <Form.Select
                                    size='sm'
                                    value={currentTeste.Indice || ''}
                                    onChange={e => handleInfoTeste(teste.IdTeste, 'Indice', e.target.value)}
                                >
                                    <option disabled value="">Selecione</option>
                                    {indices.map(indice => (
                                        <option key={indice.Indice} value={indice.Indice}>{indice.Indice}</option>
                                    ))}
                                </Form.Select>
                            </FloatingLabel>
                        </div>
                        <div className={styles.divObs}>
                            <FloatingLabel label='Observação'>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    value={currentTeste.Observacao || ''}
                                    onChange={e => handleInfoTeste(teste.IdTeste, 'Observacao', e.target.value)}
                                />
                            </FloatingLabel>
                        </div>
                    </Stack>
                );

                const renderGramaturaLarguraTest = () => (
                    <Stack direction="horizontal" gap={3} key={teste.IdTeste} className={styles.stackTestes}>
                        <div className={styles.divDesc}><em>{teste.DescricaoTeste}</em></div>
                        <div className={styles.divSelect}>
                            <FloatingLabel label='Status'>
                                <Form.Select size='sm'
                                    value={currentTeste.Status || ''}
                                    onChange={e => handleInfoTeste(teste.IdTeste, 'Status', e.target.value)}
                                >
                                    <option disabled value="">Selecione</option>
                                    <option value="Aprovado">Aprovado</option>
                                    <option value="Reprovado">Reprovado</option>
                                </Form.Select>
                            </FloatingLabel>
                        </div>
                        <div className={styles.divGramLarg}>
                            <FloatingLabel label={`${formatString(teste.DescricaoTeste)} (g/m²)`}>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    value={currentTeste[formatString(teste.DescricaoTeste)] || ''}
                                    onChange={e => handleInfoTeste(teste.IdTeste, formatString(teste.DescricaoTeste), e.target.value)}
                                />
                            </FloatingLabel>
                        </div>
                        <div className={styles.divGramLarg}>
                            <FloatingLabel label={`${formatString(teste.DescricaoTeste)} Forn. (g/m²)`}>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    value={currentTeste[`${formatString(teste.DescricaoTeste)}Fornecedor`] || ''}
                                    onChange={e => handleInfoTeste(teste.IdTeste, `${formatString(teste.DescricaoTeste)}Fornecedor`, e.target.value)}
                                />
                            </FloatingLabel>
                        </div>
                        <div className={styles.divGramLarg}>
                            <FloatingLabel label={`%Dif. ${formatString(teste.DescricaoTeste)}`}>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    disabled
                                    value={difTeste || ''}
                                />
                            </FloatingLabel>
                        </div>
                        <div className={styles.divObsGramLarg}>
                            <FloatingLabel label='Observação'>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    value={currentTeste.Observacao || ''}
                                    onChange={e => handleInfoTeste(teste.IdTeste, 'Observacao', e.target.value)}
                                />
                            </FloatingLabel>
                        </div>
                    </Stack>
                );

                return teste.IdTeste === 7 || teste.IdTeste === 18 ? renderGramaturaLarguraTest() : renderNormalTest();
            })}
        </div>
    );
};

export default TestesInfo;