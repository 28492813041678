import React from 'react';
import Tabela from '../../../Misc/Tabela/Tabela';

import { useAlteracaoDemanda } from '../../../../contexts/Corte/ControleEnfesto/AlteracaoDemandaContext';

const MainTable = () => {

    const { loading, mainData, filtro, handleShowModal } = useAlteracaoDemanda();

    const columns = [
        { name: 'ORDEM SERVICO', selector: row => row.ordemServico, atr: 'ordemServico' },
        { name: 'ENFESTADOR 1', selector: row => row.enfestadorUm, atr: 'enfestadorUm' },
        { name: 'ENFESTADOR 2', selector: row => row.enfestadorDois, atr: 'enfestadorDois' },
        { name: 'GRADE', selector: row => row.grade, atr: 'grade' },
        { name: 'FOLHAS ALOCADAS', selector: row => row.folhasAlocadas, atr: 'folhasAlocadas' },
        { name: 'FOLHAS TOTAIS', selector: row => row.folhasTotais, atr: 'folhasTotais' }
      ];

    const filteredItems = mainData.filter(item => 
        item.ordemServico.includes(filtro) 
    );

    return (
        <section style={{ margin: '0px 20px 20px' }}>
            <Tabela
                columns={columns}
                data={filteredItems}
                pending={loading}
                rowClickedFn={handleShowModal}
            />
        </section>
    );
};

export default MainTable;
