import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const TooltipComponent = ({ text, tooltip, style, placement }) => {

    return (
        <OverlayTrigger
            placement={placement}
            overlay={<Tooltip id={`tooltip-${text}`}>{tooltip}</Tooltip>}
        >
            <div style={style}>
                {text}
            </div>
        </OverlayTrigger>
    );
};

export default TooltipComponent;
