import React from "react";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

import CookiesService from "../services/cookies";
import { toast, ToastContainer } from 'react-toastify';
import DadosEntrada from "../components/LaboratorioAgendar/DadosEntrada";
import '../styles/LaboratórioAgendar.css';
import MaterialIconsReact from "material-icons-react";
import { useEffect } from "react";
import { api }  from "../services/api";
import ModalPendenciaLaboratorio from "../components/RevisaoMP/ModalPendenciaLaboratorio";

const isAgendamentosEqual = (list1, list2) => {
  let equal = [];
  list1.forEach((v, i) => {
    if(v?.agendado !== list2[i]?.agendado){
      equal.push(v);
    }
  });
  return equal;
}

const LaboratorioAgendar = () => {
  const [tipoAnalise, setTipoAnalise] = useState(parseInt(getParams('tipoAnalise')));
  const [reverterEnabled, setReverterEnabled] = useState(false);

  const [testesList, setTestesList] = useState([]);
  const [testesListInicial, setTestesListInicial] = useState([]);

  const [entrada, setEntrada] = useState({});
  const [showModalPendencia, setShowModalPendencia] = useState(false);

  let params = (new URL(document.location)).searchParams;
  const [getForm, setGetForm] = useState({
    nPeca:getParams('nPeca'),
    material:getParams('material'),
    corMaterial:getParams('corMaterial'),
    pedido:getParams('pedido')
  });


  useEffect(() => {
    setGetForm({
      nPeca:'',
      material:'',
      corMaterial:'',
      pedido:''
    })
    setTestesList([]);
    setTestesListInicial([]);
    setEntrada({})
  }, [tipoAnalise])

  function getParams(v) {
    let params = (new URL(document.location)).searchParams;
    
    if(v === 'tipoAnalise') {
      return !!params.get(v) ? params.get(v) : 1;
    }else{
      return params.get(v);
    }
  }

  useEffect(() => {

    let has = 0;

    let params = (new URL(document.location)).searchParams;
    let pList = Array.from(params.keys())
    pList.forEach(v => {
      if(v === 'tipoAnalise') {
        setTipoAnalise(parseInt(params.get(v)));
      }else{
        has = has || !!params.get(v);
        setFormProp(v, params.get(v));
      }
    });

    if(has){
      buscarFn();
    }
  }, [])

  useEffect(() => {
    
    let pParams = [
      'tipoAnalise',
      'nPeca',
      'material',
      'corMaterial',
      'pedido'
    ]

    let params = (new URL(document.location)).searchParams;
    params.set('tipoAnalise', tipoAnalise);
    Object.keys(getForm).forEach(k => {
      if(pParams.includes(k))
        params.set(k, getForm[k]);
    })
    window.history.replaceState('', document.title, document.location.pathname+`?${params.toString()}`);
  }, [tipoAnalise, getForm])

  useEffect(() => {
    const eq = isAgendamentosEqual(testesList, testesListInicial);
     setReverterEnabled(eq.length > 0);
  }, [testesList]);

  const reverterFn = () => {
    const oldList = testesList.map((v, i) => {
      return Object.assign({}, testesListInicial[i]);
    });
    setTestesList(oldList);
  }

  const setFormProp = (value, prop) => {
    let temp = {...getForm};
    temp[prop] = value;
    setGetForm(temp);
  }

  const buscarFn = async () => {

    if(tipoAnalise === 1){
      let emptyFields = !getForm.nPeca;
      if(emptyFields){
        toast.warning('Campos obrigatórios não preenchidos!');
        return;
      }
    }else{
      let emptyFields = !getForm.material || !getForm.corMaterial || !getForm.pedido;
      if(emptyFields){
        toast.warning('Campos obrigatórios não preenchidos!');
        return;
      }
    }

    try {
      const entradaRest = await api.get(`/lab-agendar/get-entrada?peca=${getForm.nPeca}&tipo_analise=${tipoAnalise}&material=${getForm.material}&cor_material=${getForm.corMaterial}&pedido=${getForm.pedido}`);
    
        if(entradaRest.data?.data?.length < 1){
          toast.warning('Entrada não encontrada!');
          return;
        }
    
        const dEntrada = entradaRest.data?.data[0];
      
        let mapEntrada = {};
        Object.keys(dEntrada).forEach(k => {
          let t = k.toLowerCase();
          t = t === 'fornecedor' ? 'nomeclifor' : t
          mapEntrada[t] = dEntrada[k];
        })
        setEntrada(mapEntrada)
        hasPendenciaMaterial(mapEntrada.material)

        const testes = await api.get(`/lab-agendar/get-testes?peca=${getForm.nPeca}&tipo_analise=${tipoAnalise}&material=${getForm.material}&cor_material=${getForm.corMaterial}&pedido=${getForm.pedido}&data_alteracao=${mapEntrada.dataalteracaopedido || entrada.dataalteracaopedido}`);
        
        setTestesListInicial(testes?.data?.data?.map(v => {
          const newV = Object.assign({}, v);
          return newV;
        }).sort((a, b) => parseInt(a['IDteste']) - parseInt(b['IDteste'])));
        
        setTestesList([...testes.data.data].sort((a, b) => parseInt(a['IDteste']) - parseInt(b['IDteste'])));

    } catch (error) {
      toast.error('Não foi possível completar sua solicitação !');
    }
   
  }

  const processarFn = async () => {

    const eq = isAgendamentosEqual(testesList, testesListInicial);
    const re_agendamentos = eq.filter(v => (!!v.DATA_TESTE && v.agendado === 1));
    const user = CookiesService.getUserLogin();
    const postObject = {...getForm, ...entrada, testes:eq, user, tipo_analise:tipoAnalise};

    if(re_agendamentos.length > 0){
      try {
        const processPromise = api.post('/lab-agendar/reagendarCancelado', postObject);
        await processPromise;
        window.location.reload(true);
      } 
      catch (e) {
        toast.error("Não foi possível re-agendar este teste");
      }  
      return;
    }
    
    if(eq.length === 0){
      toast.warning("Não houveram modificações para processar!");
      return;
    }else{
      const r = window.confirm("Deseja mesmo realizar essa solicitação?");
      if(!r) return;
    }

    const processPromise = api.post('/lab-agendar/processar', postObject);

    toast.promise(processPromise, {
      error:'Não foi possível completar sua solicitação!',
      pending:'Processando...',
      success:'Processado com sucesso!'
    });

    await processPromise;
    window.location.reload(true);

  }

  const agendaHandler = (index, value) => {
    const list = [...testesList];

    list[index].agendado = value;

    setTestesList(list);
  }

  const hasPendenciaMaterial = (material) => {
    setShowModalPendencia(material === '74280942')
  }

  const CloseModalPendencia = () => {
    setShowModalPendencia(false);
  }

  return (
    <div className={"mx-4 mt-4"}>
      <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
      />

      <h3>Laboratorio: Agendar</h3>

      <Form.Group className="d-flex flex-row">
          <Form.Label className="me-3">Tipo de análise:</Form.Label>
          <Form.Check className="me-3" checked={tipoAnalise === 1} onChange={(e) => setTipoAnalise(1)} type="radio" label="Producao"/>
          <Form.Check className="me-3" checked={tipoAnalise === 2} onChange={(e) => setTipoAnalise(2)} type="radio" label="Top Sample"/>
          {/* <Form.Check className="me-3" checked={tipoAnalise === 3} onChange={(e) => setTipoAnalise(3)} type="radio" label="Teste Base"/> */}
      </Form.Group>

      <Form.Group className="d-flex flex-row mt-4 w-75">
        <div className="d-flex flex-column me-3">
          <Form.Label className={(tipoAnalise === 2 ? "opacity-50" : null) + " text-nowrap"}>Peça:</Form.Label>
          <Form.Control value={getForm.nPeca} onChange={e => setFormProp(e.target.value, 'nPeca')} disabled={tipoAnalise === 2}/>
        </div>
        <div className="d-flex flex-column me-3">
          <Form.Label className={(tipoAnalise === 1 ? "opacity-50" : null) + " text-nowrap"}>Material:</Form.Label>
          <Form.Control value={getForm.material} onChange={e => setFormProp(e.target.value, 'material')} disabled={tipoAnalise === 1}/>
        </div>
        <div className="d-flex flex-column me-4">
          <Form.Label className={(tipoAnalise === 1 ? "opacity-50" : null) + " text-nowrap"}>Cor:</Form.Label>
          <Form.Control value={getForm.corMaterial} onChange={e => setFormProp(e.target.value, 'corMaterial')} disabled={tipoAnalise === 1}/>
        </div>
        <div className="d-flex flex-column me-4">
          <Form.Label className={(tipoAnalise === 1 ? "opacity-50" : null) + " text-nowrap"}>Pedido:</Form.Label>
          <Form.Control value={getForm.pedido} onChange={e => setFormProp(e.target.value, 'pedido')} disabled={tipoAnalise === 1}/>
        </div>
        <div className="d-flex flex-column">
            <Form.Label className="opacity-0">Cor:</Form.Label>
          <div className="d-flex flex-row ">
            <Button onClick={buscarFn} className="me-3">Buscar </Button>
          </div>
        </div>
      </Form.Group>

      <DadosEntrada className="mt-4" obj={entrada}/>
      <ModalPendenciaLaboratorio obj={entrada} closeHandler={CloseModalPendencia} show={showModalPendencia}/>

      <div className="mt-4 mb-3 border border-secondary rounded testes-table">
        <Table className="table-sm table-hover" cellSpacing={10}>
          <thead>
            <tr>
              <th></th>
              <th>ID Teste</th>
              <th>Descrição</th>
              <th>Status</th>
              <th>Data</th>
              <th>Usuário</th>
            </tr>
          </thead>
          <tbody className="text-nowrap">
            {
              testesList?.map((r, i) => {
                return <Row obj={r} index={i} agendaHandler={agendaHandler}/>
              })
            }
          </tbody>
        </Table>
      </div>

      <div className="d-flex justify-content-end mb-5">
        <Button variant="outlined-primary" className="me-3" onClick={reverterFn} disabled={!reverterEnabled}>Reverter</Button>
        <Button onClick={processarFn}>Processar</Button>
      </div>

    </div>
  );
};

const Row = (props) => {
  return (
    <tr>
      <td className="btn-div">
          <span className="cursor-pointer d-flex justify-content-center">
            <div className="px-2 pt-1">
              <span onClick={() => props.agendaHandler(props.index, 1)} className={"me-2 "+ (props.obj?.agendado === 1 ? "" : "d-none")}>
                <MaterialIconsReact
                icon="task_alt"
                size={22} 
                color='#080'/>
              </span>
              <span onClick={() => props.agendaHandler(props.index, 1)} className={"me-2 "+ (props.obj?.agendado !== 1 ? "" : "d-none")}>
                <MaterialIconsReact
                icon="task_alt"
                size={22} 
                color='#888'/>
              </span>
              <span onClick={() => props.agendaHandler(props.index, 0)} className={props.obj?.agendado === 0 ? "" : "d-none"}>
                <MaterialIconsReact
                icon="close"
                size={22} 
                color='#800'/>
              </span>
              <span onClick={() => props.agendaHandler(props.index, 0)} className={props.obj?.agendado !== 0 ? "" : "d-none"}>
                <MaterialIconsReact
                icon="close"
                size={22} 
                color='#888'/>
              </span>
            </div>
          </span>
      </td>
      <td className="p-2">{props.obj?.IDteste || '-'}</td>
      <td className="p-2">{props.obj?.DescricaoTeste || '-'}</td>
      <td className="p-2">{props.obj?.STATUS_TESTE || '-'}</td>
      <td className="p-2">{props.obj?.DATA_TESTE || '-'}</td>
      <td className="p-2">{props.obj?.USUARIO || '-'}</td>
    </tr>
  )
}

export default LaboratorioAgendar;