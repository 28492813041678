import { createContext, useEffect, useContext, useState } from 'react';
import { api } from '../../../services/api';
import { toast } from 'react-toastify';
import CookiesService from '../../../services/cookies';
import { toDatetime, toUTCDDMMYYYY } from '../../../utils/dateUtils';
import { useMisc } from '../../MiscContext';

const LancamentoCadContext = createContext();
export const useLancamentoCad = () => useContext(LancamentoCadContext);

export const LancamentoCadProvider = ({ children }) => {

  const { setShowLoading } = useMisc();

  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });

  const handleSort = (key) => {
    let direction = 'desc';
    if (sortConfig.key === key && sortConfig.direction === 'desc') {
      direction = 'asc';
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    if (sortConfig.key) {
      const sortedData = [...mainData].sort((a, b) => {
        const column = checklistColumns.find(col => col.atr === sortConfig.key);
        const valA = column.selector(a);
        const valB = column.selector(b);

        if (valA < valB) return sortConfig.direction === 'asc' ? -1 : 1;
        if (valA > valB) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
      setMainData(sortedData);
    }
  }, [sortConfig]);

             const checklistColumns = [
        {
          name: (
            <span onClick={() => handleSort('DataSolicitacao')} style={{ cursor: 'pointer' }}>
              DATA SOLICITAÇÃO {sortConfig.direction === 'asc' ? '▲' : '▼'}
            </span>
          ),
          selector: row => toUTCDDMMYYYY(row.DataSolicitacao),
          atr: 'DataSolicitacao'
        },
        {
          name: (
            <span onClick={() => handleSort('LT')} style={{ cursor: 'pointer' }}>
              LT {sortConfig.direction === 'asc' ? '▲' : '▼'}
            </span>
          ),
          selector: row => row.LT,
          atr: 'LT'
        },
        {
          name: (
            <span onClick={() => handleSort('OrdemServico')} style={{ cursor: 'pointer' }}>
              ORDEM SERVIÇO {sortConfig.direction === 'asc' ? '▲' : '▼'}
            </span>
          ),
          selector: row => row.OrdemServico,
          atr: 'OrdemServico'
        },
        {
          name: (
            <span onClick={() => handleSort('OrdemProducao')} style={{ cursor: 'pointer' }}>
              ORDEM PRODUÇÃO {sortConfig.direction === 'asc' ? '▲' : '▼'}
            </span>
          ),
          selector: row => row.OrdemProducao,
          atr: 'OrdemProducao'
        },
        {
          name: (
            <span onClick={() => handleSort('Marca')} style={{ cursor: 'pointer' }}>
              MARCA {sortConfig.direction === 'asc' ? '▲' : '▼'}
            </span>
          ),
          selector: row => row.Marca,
          atr: 'Marca'
        },
        {
          name: (
            <span onClick={() => handleSort('Canal')} style={{ cursor: 'pointer' }}>
              CANAL {sortConfig.direction === 'asc' ? '▲' : '▼'}
            </span>
          ),
          selector: row => row.Canal,
          atr: 'Canal'
        },
        {
          name: (
            <span onClick={() => handleSort('Produto')} style={{ cursor: 'pointer' }}>
              PRODUTO {sortConfig.direction === 'asc' ? '▲' : '▼'}
            </span>
          ),
          selector: row => row.Produto,
          atr: 'Produto'
        },
        {
          name: (
            <span onClick={() => handleSort('DescProduto')} style={{ cursor: 'pointer' }}>
              DESCRIÇÃO {sortConfig.direction === 'asc' ? '▲' : '▼'}
            </span>
          ),
          selector: row => row.DescProduto,
          atr: 'DescProduto'
        },
        {
          name: (
            <span onClick={() => handleSort('TipoCorteLinx')} style={{ cursor: 'pointer' }}>
              TIPO CORTE LINX {sortConfig.direction === 'asc' ? '▲' : '▼'}
            </span>
          ),
          selector: row => row.TipoCorteLinx,
          atr: 'TipoCorteLinx'
        },
        {
          name: (
            <span onClick={() => handleSort('CorOP')} style={{ cursor: 'pointer' }}>
              COR OP {sortConfig.direction === 'asc' ? '▲' : '▼'}
            </span>
          ),
          selector: row => row.CorOP,
          atr: 'CorOP'
        }
      ];

  const userLogin = CookiesService.getUserLogin();
  const dataProcesso = toDatetime(new Date());

  const gradeOrder = ['PP', 'P', 'M', 'G', 'GG', 'XG', 'XGG']

  const parseFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result.split(',')[1])
        } else {
          reject(new Error('Failed to read file'))
        }
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }

  const [mainData, setMainData] = useState([]);
  const [tipoCorteData, setTipoCorteData] = useState([])
  const [plantaCorteData, setPlantaCorteData] = useState([])
  const [tamanhosData, setTamanhosData] = useState([])
  const [materialCorData, setMaterialCorData] = useState([])
  const [materiaisRestantes, setMateriaisRestantes] = useState([])

  const [linhaSelecionada, setLinhaSelecionada] = useState({});
  const [tipoCorteSelecionado, setTipoCorteSelecionado] = useState({});
  const [plantaCorteSelecionado, setPlantaCorteSelecionado] = useState({});
  const [linhaConfSelecionada, setLinhaConfSelecionada] = useState({})

  const [qtdeLocalizacao, setQtdeLocalizacao] = useState('')
  const [materialSelecionado, setMaterialSelecionado] = useState({})
  const [numRisco, setNumRisco] = useState('')
  const [comprimentoRisco, setComprimentoRisco] = useState('')

  const [file, setFile] = useState('');

  const [tipoCorteMaterial, setTipoCorteMaterial] = useState({})
  const [riscoSelecionado, setRiscoSelecionado] = useState([])
  const [tamanhosSelecionados, setTamanhosSelecionados] = useState([])
  const [riscosInseridos, setRiscosInseridos] = useState([])
  const [gradeFolha, setGradeFolha] = useState([])
  const [folhas, setFolhas] = useState([])
  const [materiaisFinalizados, setMateriaisFinalizados] = useState([])
  const [materiaisFinalizadosUnique, setMateriaisFinalizadosUnique] = useState([])

  const [filterObj, setFilterObj] = useState({
      op: '',
      os: ''
  });

  const [loading, setLoading] = useState(false);

  const [showModalLocalTipoPlanta, setShowModalLocalTipoPlanta] = useState(false)
  const [showModalMateriais, setShowModalMateriais] = useState(false)
  const [showModalFolha, setShowModalFolha] = useState(false)
  const [showModalVoltar, setShowModalVoltar] = useState(false)
  const [showModalRiscos, setShowModalRiscos] = useState(false)
  const [showModalConfirmacao, setShowModalConfirmacao] = useState(false)
  const [showModalConfirmacaoRiscos, setShowModalConfirmacaoRiscos] = useState(false)

  const [habilitaLocal, setHabilitaLocal] = useState(false)
  const [habilitaAvancar, setHabilitaAvancar] = useState(false)
  const [habilitaInserir, setHabilitaInserir] = useState(false)
  const [habilitaFinalizarProd, setHabilitaFinalizarProd] = useState(false)
  const [habilitaIncluirRisco, setHabilitaIncluirRisco] = useState(false)
  const [habilitaFinalizarMat, setHabilitaFinalizarMat] = useState(false)
  const [habilitaFinalizarFolha, setHabilitaFinalizarFolha] = useState(false)

  const handleShowModalLocalTipoPlanta = (row) => {
    fetchMaterialCorTamanhos(row)
  }

  const handleCloseModalLocalTipoPlanta = () => {
    handleResetLocalTipoPlanta()
    setShowModalLocalTipoPlanta(false)
  }

  const handleShowModalMateriais = () => {
    setShowModalLocalTipoPlanta(false)
    setShowModalMateriais(true)
  }

  const handleCloseModalMateriais = () => {
    setShowModalMateriais(false)
    setShowModalVoltar(true)
  }

  const handleShowModalFolha = () => {
    const gradesUnicas = Array.from(new Set(riscosInseridos.map(risco => risco.Grade)))
    const gradesSorted = gradesUnicas.sort((a, b) => { return gradeOrder.indexOf(a) - gradeOrder.indexOf(b) })
    setGradeFolha(gradesSorted)
    setShowModalRiscos(false)
    setShowModalFolha(true)
  }

  const handleCloseModalFolha = () => {
    setGradeFolha([])
    setFolhas({})
    setShowModalFolha(false)
    setShowModalRiscos(true)
  }

  const handleConfirmaVoltar = () => {
    handleResetMateriais()
    setShowModalLocalTipoPlanta(true)
    setShowModalVoltar(false)
  }

  const handleNaoConfirmaVoltar = () => {
    setShowModalVoltar(false)
    setShowModalMateriais(true)
  }

  const handleShowModalRiscos = () => {
    setShowModalRiscos(true)
    setShowModalMateriais(false)
  }

  const handleCloseModalRiscos = () => {
    handleResetRiscos()
    setShowModalRiscos(false)
    setShowModalMateriais(true)
  }

  const handleShowModalConfirmacao = () => {
    setShowModalMateriais(false)
    setShowModalConfirmacao(true)
  }

  const handleCloseModalConfirmacao = () => {
    setShowModalConfirmacao(false)
    setShowModalMateriais(true)
  }

  const handleShowModalConfirmacaoRiscos = (item) => {
    setLinhaConfSelecionada(item)
    setShowModalConfirmacao(false)
    setShowModalConfirmacaoRiscos(true)
  }

  const handleCloseModalConfirmacaoRiscos = () => {
    setLinhaConfSelecionada({})
    setShowModalConfirmacaoRiscos(false)
    setShowModalConfirmacao(true)
  }

  const handleQtdeLocalizacao = (e) => {
    const regex = /^\d*$/
    if (!regex.test(e.target.value)){
        return
    }
    const qtde = parseInt(e.target.value)
    setQtdeLocalizacao(qtde)
  }

  const handleCheckMateriCor = (e, mat) => {
    const isChecked = e.target.checked;
    const materialChecked = isChecked ? mat : null

    setMaterialSelecionado(materialChecked)
  }

  const handleNumRisco = (e) => {
    const regex = /^\d*$/
    if (!regex.test(e.target.value)){
        return
    }
    const num = parseInt(e.target.value)
    setNumRisco(num)
  }

  const handleCheckRisco = (e, risco) => {
    const isChecked = e.target.checked;

    const arrayRiscos = isChecked
      ?[ ...riscoSelecionado, risco ]
      : riscoSelecionado.filter(item => item !== risco)

      setRiscoSelecionado(arrayRiscos)
  }

  const handleCheckTamanhos = (e, tamanho) => {
    const isChecked = e.target.checked;

    const arrayTamanhos = isChecked 
      ? [ ...tamanhosSelecionados, tamanho ]
      : tamanhosSelecionados.filter(item => item.idx !== tamanho.idx)

    setTamanhosSelecionados(arrayTamanhos);
  }

  const handleComprimentoRisco = (e) => {
    const regex = /^\d*(?:\.\d{0,2})?$/
    if (!regex.test(e.target.value)){
        return
    }
    const comprimento = e.target.value
    setComprimentoRisco(comprimento)
  }

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {          
        setFile(selectedFile);
    }
  };

  const handleFolhas = (e, grade) => {
    const regex = /^\d*$/
    if (!regex.test(e.target.value)){
        return
    }
    const folha = {...folhas};
    folha[grade] = e.target.value;
    setFolhas(folha);
  }

  const handleInserirRisco = () => {

    const inseridos = []

    riscoSelecionado.forEach(risco => {
      const riscos = tamanhosSelecionados.map(tamanho => ({
        ...tamanho,
        comprimentoRisco,
        risco: risco
      }))
      inseridos.push(...riscos);
    })

    setRiscosInseridos([...riscosInseridos, ...inseridos])
    setTamanhosSelecionados([])
    setComprimentoRisco('')
    setRiscoSelecionado([])
  }

  const handleDeleteInserido = (item) => {
    setRiscosInseridos(riscosInseridos.filter(risco => risco.risco !== item.risco));
    setRiscoSelecionado([])
    setTamanhosSelecionados([])
    setComprimentoRisco('')
  }

  const  handleFinalizaMaterial = async() => {

    const base64String = await parseFile(file)

    const materialRiscos = {
        cad: {
          material: materialSelecionado.material,
          cor: materialSelecionado.cor_material,
          qtdeRisco: numRisco,
          tipoCorteMat: tipoCorteMaterial.id_producao_tipo_corte
        },
        riscos: riscosInseridos,
        folhas: folhas,
        img: base64String
      }
  
    setMateriaisFinalizados([...materiaisFinalizados, materialRiscos])
    setMateriaisFinalizadosUnique(
      [...materiaisFinalizadosUnique, {
          material: materialSelecionado.material,
          desc_material: materialSelecionado.desc_material,
          cor_material: materialSelecionado.cor_material
        }
      ])
    handleResetRiscos()
    setMaterialSelecionado({})
    setNumRisco('')
    setTipoCorteMaterial({})
    setShowModalFolha(false)
    setShowModalMateriais(true)
  }
  
  const handleDeleteFinalizado = (item) => {
    setMateriaisFinalizados(materiaisFinalizados.filter(material => material.cad.material !== item.material && material.cad.cor !== item.cor_material));
    setMateriaisFinalizadosUnique(materiaisFinalizadosUnique.filter(material => material.material !== item.material && material.cor_material !== item.cor_material));
  }

  const handleResetLocalTipoPlanta = () => {
    setQtdeLocalizacao('')
    setTipoCorteSelecionado({})
    setPlantaCorteSelecionado({})
    setLinhaSelecionada({})
    setMaterialCorData([])
    setMateriaisRestantes([])
    setTamanhosData([])
  }

  const handleResetMateriais = () => {
    setMaterialSelecionado({})
    setNumRisco('')
    setTipoCorteMaterial({})
    setMateriaisFinalizados([])
    setMateriaisFinalizadosUnique([])
  }

  const handleResetRiscos = () => {
    setRiscoSelecionado([])
    setTipoCorteMaterial({})
    setRiscosInseridos([])
    setTamanhosSelecionados([])
    setComprimentoRisco('')
    setGradeFolha([])
    setFolhas({})
    setFile('')
  }

  const handleResetAll = () => {
    handleResetLocalTipoPlanta()
    handleResetMateriais()
    handleResetRiscos()
  }

  const fetchMainData = async () => {
    setLoading(true);
    const params = { ...filterObj }
    await api.get('Corte/ControleEnfesto/LancamentoCad/get-rows', { params })
      .then(res => {
        setMainData(res.data);
        setLoading(false);
      }).catch(e => {
        toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
      });
  };

  const fetchMaterialCorTamanhos = async (produto) => {
    setShowLoading(true)
    const params = { ...produto }
    await api.get('Corte/ControleEnfesto/LancamentoCad/get-material-cor-tamanhos', { params })
      .then(res => {
        setMaterialCorData(res.data.resMaterialCor);
        setMateriaisRestantes(res.data.resMaterialCor)
        setTamanhosData(res.data.resTamanhos);
        setLinhaSelecionada(produto)
        setShowModalLocalTipoPlanta(true)
      }).catch(e => {
        toast.error('Ocorreu um erro ao carregar os dados, por favor cheque sua internet e/ou entre contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
      });
    setShowLoading(false)
  };

  const fetchTipoPlantaData = async () => {
    setShowLoading(true)
    await api.get('Corte/ControleEnfesto/LancamentoCad/get-tipo-planta')
      .then(res => {
        setTipoCorteData(res.data.resTipoCorte)
        setPlantaCorteData(res.data.resPlantaCorte)
      }).catch(e => {
        toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
      });
    setShowLoading(false)
  };

  const handleProcessar = async () => {
    setShowLoading(true)
    setShowModalConfirmacao(false)
    const params = { 
      cad: materiaisFinalizados,
      info: {
        op: linhaSelecionada.OrdemProducao,
        os: linhaSelecionada.OrdemServico,
        qtdeLocalizacao: qtdeLocalizacao,
        tipoCorte: tipoCorteSelecionado.desc_producao_tipo_corte,
        plantaCorte: plantaCorteSelecionado.DescricaoPlantaCorte,
        usuario: userLogin,
        dataRegistro: dataProcesso
      }
    }
    try {
        const res = await api.post('Corte/ControleEnfesto/LancamentoCad/processa-cad', { params });
        if (res.status === 200) {
            toast.success('Dados inseridos com sucesso!');
            handleResetAll()
            setMainData([])
        } else {
            toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
        }
    } catch (e) {
        setShowModalConfirmacao(true)
        toast.error('Ocorreu um erro ao salvar os dados. Por favor cheque sua internet e/ou entre em contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
    }
    setShowLoading(false)
  };

  useEffect(() => {
    fetchTipoPlantaData()
  }, [])

  useEffect(() => {
    if (Object.keys(tipoCorteSelecionado).length === 0 || tipoCorteSelecionado.id_producao_tipo_corte === 5){
      setHabilitaLocal(false)
      setQtdeLocalizacao(0)
    } else {
      setHabilitaLocal(true)
      setQtdeLocalizacao('')
    }
  },[tipoCorteSelecionado])

  useEffect(() => {
    if (habilitaLocal){
      if ( Object.keys(tipoCorteSelecionado).length > 0 &&
           Object.keys(plantaCorteSelecionado).length > 0 &&
           qtdeLocalizacao > 0) {
        setHabilitaAvancar(true)
      } else setHabilitaAvancar(false)
    } else {
      if ( Object.keys(tipoCorteSelecionado).length > 0 &&
           Object.keys(plantaCorteSelecionado).length > 0 ) {
        setHabilitaAvancar(true)
      } else setHabilitaAvancar(false)      
    }

  }, [tipoCorteSelecionado, plantaCorteSelecionado, qtdeLocalizacao])

  useEffect(() =>{
    (materiaisFinalizadosUnique.length === materialCorData.length) && materialCorData.length !== 0 ? setHabilitaFinalizarProd(true) : setHabilitaFinalizarProd(false)
  },[materiaisFinalizadosUnique])

  useEffect(() => {
    if ( Object.keys(materialSelecionado).length > 0 &&
         numRisco > 0 ){
      setHabilitaInserir(true)
    } else setHabilitaInserir(false)
  },[materialSelecionado, numRisco])

  useEffect(() => {
    if ( riscoSelecionado.length > 0 &&
         tamanhosSelecionados.length > 0 &&
         comprimentoRisco > 0 &&
         comprimentoRisco <= 10){
      setHabilitaIncluirRisco(true)
    } else setHabilitaIncluirRisco(false)
  },[riscoSelecionado, tamanhosSelecionados, comprimentoRisco])

  useEffect(() => {
    const riscosUnicos = Array.from(new Set(riscosInseridos.map(risco => risco.risco)));
    if (riscosUnicos.length === numRisco && file != '' && Object.keys(tipoCorteMaterial).length > 0){
      setHabilitaFinalizarMat(true)
    } else setHabilitaFinalizarMat(false)
  },[riscosInseridos, numRisco, file, tipoCorteMaterial])

  useEffect(() => {
    const folhasPreenchidas = gradeFolha.every(grade => folhas[grade] && parseInt(folhas[grade]) > 0)
    setHabilitaFinalizarFolha(folhasPreenchidas)
  },[gradeFolha, folhas])

  return (
    <LancamentoCadContext.Provider
      value={{ setTipoCorteMaterial, tipoCorteMaterial, habilitaLocal, file, handleFileChange, handleFolhas, gradeFolha, folhas, habilitaFinalizarFolha, showModalFolha, handleShowModalFolha, handleCloseModalFolha, handleProcessar, linhaConfSelecionada, handleCloseModalConfirmacaoRiscos, handleShowModalConfirmacaoRiscos, showModalConfirmacaoRiscos, handleCloseModalConfirmacao, handleShowModalConfirmacao, showModalConfirmacao, materiaisFinalizadosUnique, handleDeleteFinalizado, handleFinalizaMaterial, habilitaFinalizarMat, handleDeleteInserido, handleInserirRisco, habilitaIncluirRisco, handleResetRiscos, handleComprimentoRisco, comprimentoRisco, handleCheckRisco, riscoSelecionado, handleCloseModalRiscos, handleShowModalRiscos, showModalRiscos, setHabilitaFinalizarProd, habilitaFinalizarProd, habilitaInserir, showModalVoltar, handleConfirmaVoltar, handleNaoConfirmaVoltar, materiaisFinalizados, riscosInseridos, tamanhosSelecionados, handleCheckTamanhos, handleNumRisco, materialSelecionado, numRisco, handleCheckMateriCor, habilitaAvancar, showModalMateriais, handleCloseModalMateriais, handleShowModalMateriais, qtdeLocalizacao, handleQtdeLocalizacao, tipoCorteSelecionado, plantaCorteSelecionado, setTipoCorteSelecionado, setPlantaCorteSelecionado, showModalLocalTipoPlanta, fetchMaterialCorTamanhos, handleShowModalLocalTipoPlanta, handleCloseModalLocalTipoPlanta, loading, mainData, tipoCorteData, plantaCorteData, tamanhosData, linhaSelecionada, filterObj, fetchMainData, setFilterObj, materialCorData, materiaisRestantes, handleSort, sortConfig, checklistColumns, handleResetAll }}
    >
      {children}
    </LancamentoCadContext.Provider>
  )
}