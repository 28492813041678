import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import styles from '../../../styles/MP/Triangulacao.module.scss';
import { useTriangulacao } from '../../../contexts/MP/TriangulacaoContext'; 

const TestesSelect = () => {

    const { testesRowData, testesData, testesSelecionados, handleSelecionaTeste } = useTriangulacao();

    const update = testesRowData && testesRowData.length > 0

    return (
        <>
            <h5 className={styles.testesTitle}>Testes</h5>
            <Form>
                <Row>
                    {
                        testesData.map((item, index) => {
                            return (
                                <Col key={index} md={6} lg={3}>
                                    <Form.Check>
                                        <Form.Check.Input
                                            type='checkbox'
                                            checked={ testesSelecionados.filter(i => i.IdTeste === item.IdTeste).length > 0 }
                                            disabled={item.IdTeste === 7 || item.IdTeste === 13 || item.IdTeste === 18}
                                            onChange={e => handleSelecionaTeste(e, item)}
                                        />
                                        <Form.Check.Label>{item.DescricaoTeste}</Form.Check.Label>
                                    </Form.Check>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Form>
        </>
    );
};

export default TestesSelect;
