import { Routes, Route } from "react-router-dom";

import Adm from "../pages/Adm";
import AdmConsultarCatalogo from "../pages/AdmConsultarCatalogo";
import AdmImpressaoEtiquetas from "../pages/AdmImpressaoEtiquetas";
import AdmEnvioRetornoAmostra from "../pages/AdmEnvioRetornoAmostra";
import AdmMovimentacao from "../pages/AdmMovimentacao";
import AdmVigenciaExclusao from "../pages/AdmVigenciaExclusao";
import AlterarLaboratorio from "../pages/AdmMp/AlterarLaboratorio";
import AlterarRevisao from "../pages/AdmMp/AlterarRevisao";
import AprovacaoEstilo from "../pages/AprovacaoEstilo";
import AprovacarAmostra from "../pages/AprovarAmostra";
import AdmAmostras from "../pages/AdmAmostras";
import CadastroAmostras from "../pages/CadastroAmostras";
import SalaAmostras from "../pages/SalaAmostra";
import Desconto from "../pages/Desconto";
import EditarPendenciar from "../pages/ADM/Desconto/EditarPendenciar"
import CadastroTeste from "../pages/CadastroTeste";

import CancelarSaidaPerda from "../pages/CancelarSaidaPerda";
import EnvioPlotter from "../pages/EnvioPlotter";
import Laboratorio from "../pages/LaboratorioMonitorar";
import LaboratorioAgendar from "../pages/LaboratorioAgendar";
import Processos from "../pages/Processos";
import RecebimentoMp from "../pages/RecebimentoMp";
import Revisao from "../pages/Revisao";
import AgendamentoRevisao from "../pages/AgendamentoRevisao";
import RevisaoLaboratorio from "../pages/RevisaoLaboratorio";
import MedicaoRapport from "../pages/MedicaoRapport";
import EditarExcluirRapport from "../pages/EditarExcluirRapport";
import RapportReprovado from "../pages/MP/RapportReprovado";
import AnaliseRapportReprovadoCad from "../pages/MP/AnaliseRapportReprovadoCad";
import RevisaoCemPorcentoRetorno from "../pages/RevisaoCemPorcentoRetorno";
import LaboratorioCemPorcentoRetorno from "../pages/MP/LaboratorioCemPorcentoRetorno";
import ReclassificacaoChamados from "../pages/ReclassificacaoChamados";

import TesteBase from "../pages/TesteBase";
import TesteBaseSourcing from "../pages/TesteBaseSourcing";

import FormularioAnalise from "../pages/FormularioAnalise";
import Devolucao from "../pages/Devolucao";

import AdmProcessoEntradaFilialRevisao from "../pages/AdmProcessoEntradaFilialRevisao";
import RevisaoImportado from "../pages/RevisaoImportado";

import CatalogoPecaPiloto from "../pages/CatalogoPecaPiloto";

import Corte from "../pages/Corte";
import PlanejamentoDiarioCorte from "../pages/PlanejamentoDiarioCorte";

import SelecaoRiscos from "../pages/Corte/ControleEnfesto/SelecaoRiscos";

import LancamentoCad from "../pages/Corte/ControleEnfesto/LancamentoCad";
import { LancamentoCadProvider } from "../contexts/Corte/ControleEnfesto/LancamentoCadContext";

import PriorizacaoOps from "../pages/Corte/ControleEnfesto/PriorizacaoOps";
import { PriorizacaoOpsProvider } from "../contexts/Corte/ControleEnfesto/PriorizacaoOpsContext";

import DistribuicaoDemanda from "../pages/Corte/ControleEnfesto/DistribuicaoDemanda";
import { DistribuicaoDemandaProvider } from "../contexts/Corte/ControleEnfesto/DistribuicaoDemandaContext";

import Enfesto from "../pages/Corte/ControleEnfesto/Enfesto";
import { EnfestoProvider } from "../contexts/Corte/ControleEnfesto/EnfestoContext";

import AcompanhamentoEnfesto from "../pages/Corte/ControleEnfesto/AcompanhamentoEnfesto";
import { AcompanhamentoEnfestoProvider } from "../contexts/Corte/ControleEnfesto/AcompanhamentoEnfestoContext";


import AlteracaoDemanda from "../pages/Corte/ControleEnfesto/AlteracaoDemanda";
import { AlteracaoDemandaProvider } from "../contexts/Corte/ControleEnfesto/AlteracaoDemandaContext";

import PASalaAmostras from "../pages/PA/SalaAmostra";
import PACadastroAmostras from "../components/PA/PecaPiloto/CadastroAmostras/CadastroAmostras";
import PAAdmImpressaoEtiquetas from "../pages/PA/AdmImpressaoEtiquetas";
import PAConsultaExclusao from "../pages/PA/PAConsultaExclusao";

import PAMonitorRevisao from "../pages/PA/MonitorRevisaoConsulta";
import PAMonitorRevisaoIdentificacao from "../pages/PA/MonitorRevisaoIdentificacao";
import PAMonitorRevisaoAnaliseGrade from "../pages/PA/MonitorRevisaoAnaliseGrade";

import PAMonitorChecklist from "../pages/PA/MonitorChecklist";

// import PAAdmControleEnvioAprovacao from "../pages/PA/ADM/ControleEnvioAprovacao";
import PAAdmAlteracaoRevisaoChecklist from "../pages/PA/ADM/AlteracaoRevisaoChecklist";
import PAAdmDevolucaoPa from "../pages/PA/ADM/DevolucaoPa";
import PAAdmOrientacaoConserto from "../pages/PA/ADM/OrientacaoConserto";
import PAAdmMedidas from "../pages/PA/ADM/Medidas";
// import PAAdmDirecionamentoPecas from "../pages/PA/ADM/DirecionamentoPecas";
import PAAdmEntradaFiscal from "../pages/PA/ADM/EntradaFiscal";
import PADesfazerMain from "../components/PA/Desfazer/mainDesfazer";

import PAControleAprovacao from "../components/PA/MonitorChecklist/ControleAprovacao/ControleAprovacao";
import PARevisaoExterna from "../pages/PA/RevisaoExterna.jsx";

import PAAgendamentoDevolucao from "../pages/PA/AgendamentoDevolucao";
import PAControleDevolucao from "../pages/PA/ControleDevolucao";
import PickingMateriais from '../pages/pesagem/PickingMateriais'

import Auth from "../pages/Auth";
import PrivateRoute from "./PrivateRoute";
import { PAChecklistProvider } from "../contexts/PAChecklistContext";
import { PAReversaProvider } from "../contexts/PAReversaContext";
import { PARevisaoProvider } from "../components/PA/Revisao/pa_revisao_context.js";
// import { PAChecklistProvider as NewPAChecklistProvider } from "../components/PA/Checklist/pa_checklist_context.js";
import PAReversaMain from "../components/PA/Reversa/PAReversaMain";
import { PAGerarLaudoProvider } from "../components/PA/GerarLaudo/gerar_laudo_context.js";

import { PARevisaoSegundaQualidadeNVProvider } from "../components/PA/RevisaoSegundaQualidadeNatiVozza/pa_revisao_sq_nv_context.js";

import { MPCadastroAmostraProvider } from "../contexts/MPCadastroAmostraContext";
import CadastroAmostrasV2 from "../components/MP/CadastroAmostras/CadastroAmostras";

import RapportEnvioCad from "../pages/MP/RapportEnvioCad"
import RapportAnalisePlanner from "../pages/MP/RapportAnalisePlanner"

import MateriaisComposicao from "../pages/FarmGlobal/MateriaisComposicao"
import FGTesteDeBase from "../pages/FarmGlobal/TesteDeBase/FGTesteDeBaseAgendar.jsx";
import FGTesteDeBaseLaboratorio from "../pages/FarmGlobal/TesteDeBase/FGTesteDeBaseLaboratorio.jsx"
import { FGReportsFarmGlobalProvider } from "../components/FarmGlobal/ReportsFarmGlobal/ReportsFarmGlobalContext.js";

import Triangulacao from "../pages/MP/Triangulacao";
import { TriangulacaoProvider } from "../contexts/MP/TriangulacaoContext";

import Main from "../pages/Main.jsx";

const Router = () => (
  <Routes>
    <Route path="/" element={<Main />} />
    <Route path="/auth" element={<Auth />} />
    <Route path="/" element={<PrivateRoute areas={['Adm - MP']} />}>
      <Route path="/adm" element={<Adm />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Adm MP - MP']} />}>
      <Route path="/alterarlaboratorio" element={<AlterarLaboratorio />} />
    </Route>
    <Route path="/" element={<PrivateRoute areas={['Adm MP - MP']} />}>
      <Route path="/alterarrevisao" element={<AlterarRevisao />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Aprovacao Estilo - MP']} />}>
      <Route path="/aprovacaoestilo" element={<AprovacaoEstilo />} />
      <Route path="/aprovaramostra" element={<AprovacarAmostra />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Catalogo de Amostras - MP']} />}>
      <Route path="/admconsultarcatalogo" element={<AdmConsultarCatalogo />} />
    </Route>
    <Route path="/" element={<PrivateRoute areas={['Catalogo de Amostras - MP']} />}>
      <Route path="/admimpressaoetiquetas" element={<AdmImpressaoEtiquetas />} />
    </Route>
    <Route path="/" element={<PrivateRoute areas={['Catalogo de Amostras - MP']} />}>
      <Route path="/admenvioretornoamostras" element={<AdmEnvioRetornoAmostra />} />
    </Route>
    <Route path="/" element={<PrivateRoute areas={['Catalogo de Amostras - MP']} />}>
      <Route path="/admvigenciaexclusao" element={<AdmVigenciaExclusao />} />
    </Route>
    <Route path="/" element={<PrivateRoute areas={['Catalogo de Amostras - MP']} />}>
      {/* <Route path="/cadastroamostras" element={<CadastroAmostras />} /> */}
      <Route path="/cadastroamostras" element={
        <MPCadastroAmostraProvider>
          <CadastroAmostrasV2 />
        </MPCadastroAmostraProvider>
      } />
    </Route>
    <Route path="/" element={<PrivateRoute areas={['Catalogo de Amostras - MP']} />}>
      <Route path="/salaamostras" element={<SalaAmostras />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Desconto - MP']} />}>
      <Route path="/desconto" element={<Desconto />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Desconto - MP']} />}>
      <Route path="/editarpendenciar" element={<EditarPendenciar />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Cadastro de Teste - MP']} />}>
      <Route path="/cadastroteste" element={<CadastroTeste />} />
    </Route>

    {/* <Route path="/" element={<PrivateRoute areas={['Cancelar Saida Perda - MP']}/>}>
      <Route path="/cancelarsaidaperda" element={<CancelarSaidaPerda />} />
    </Route> */}

    <Route path="/" element={<PrivateRoute areas={['Envio Plotter - MP']} />}>
      <Route path="/envioplotter" element={<EnvioPlotter />} />
    </Route>
    <Route path="/laboratorio/monitorar" element={<Laboratorio />} />
    <Route path="/laboratorio/agendar" element={<LaboratorioAgendar />} />
    <Route path="/" element={<PrivateRoute areas={['Laboratorio - MP']} />}>
      <Route path="/laboratorio/monitorar" element={<Laboratorio />} />
      <Route path="/laboratorio/agendar" element={<LaboratorioAgendar />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Processos - MP']} />}>
      <Route path="/processos" element={<Processos />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Recebimento - MP']} />}>
      <Route path="/recebimentomp" element={<RecebimentoMp />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Revisao - MP']} />}>
      <Route path="/revisao" element={<Revisao />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Agendamento Revisao - MP']} />}>
      <Route path="/analises/agendamentorevisao" element={<AgendamentoRevisao />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Revisao Laboratorio - MP']} />}>
      <Route path="/analises/revisaolaboratorio" element={<RevisaoLaboratorio />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Revisao CemPorcento Retorno - MP']} />}>
      <Route path="/analises/revisaocemporcentoretorno" element={<RevisaoCemPorcentoRetorno />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Revisao CemPorcento Retorno - MP']} />}>
      <Route path="/analises/laboratoriocemporcentoretorno" element={<LaboratorioCemPorcentoRetorno />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Medicao Rapport - MP']} />}>
      <Route path="/rapport/medicaorapport" element={<MedicaoRapport />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Medicao Rapport - MP']} />}>
      <Route path="/rapport/editarexcluir" element={<EditarExcluirRapport />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Medicao Rapport - MP']} />}>
      <Route path="/rapport/rapportreprovado" element={<RapportReprovado />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Medicao Rapport - MP']} />}>
      <Route path="/rapport/analiserapportreprovadocad" element={<AnaliseRapportReprovadoCad />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Envio CAD - MP']} />}>
      <Route path="/rapport/rapportenviocad" element={<RapportEnvioCad />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Medicao Rapport - MP']} />}>
      <Route path="/rapport/rapportanaliseplanner" element={<RapportAnalisePlanner />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Chamados - MP']} />}>
      <Route path="/chamados/reclassificacaochamados" element={<ReclassificacaoChamados />} />
    </Route>

    {/* <Route path="/" element={<PrivateRoute areas={['Teste Base - MP']}/>}>
      <Route path="/testebase" element={<TesteBase />} />
    </Route> */}

    {/* <Route path="/" element={<PrivateRoute areas={['Teste Base Sourcing - MP']}/>}>
      <Route path="/testebasesourcing" element={<TesteBaseSourcing />} />
    </Route> */}

    {/* PEÇA PILOTO */}
    <Route path="/" element={<PrivateRoute areas={['Catalogo Peca Pilot - PA']} />}>
      <Route path="/PA/salaamostras" element={<PASalaAmostras />} />
    </Route>
    <Route path="/" element={<PrivateRoute areas={['Catalogo Peca Pilot - PA']} />}>
      <Route path="/PA/cadastroamostras" element={<PACadastroAmostras />} />
    </Route>
    <Route path="/" element={<PrivateRoute areas={['Catalogo Peca Pilot - PA']} />}>
      <Route path="/PA/admimpressaoetiquetas" element={<PAAdmImpressaoEtiquetas />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Catalogo Peca Pilot - PA']} />}>
      <Route path="/PA/consultaexclusao" element={<PAConsultaExclusao />} />
    </Route>


    {/* ENTRADA FILIAL REVISAO PA */}
    <Route path="/" element={<PrivateRoute areas={['Monitor Revisao - PA']} />}>
      <Route path="/PA/monitorrevisao" element={<PAMonitorRevisao />} />
    </Route>
    <Route path="/" element={<PrivateRoute areas={['Monitor Revisao - PA']} />}>
      <Route path="/PA/monitorrevisaoidentificacao" element={<PAMonitorRevisaoIdentificacao />} />
    </Route>
    <Route path="/" element={<PrivateRoute areas={['Monitor Revisao - PA']} />}>
      <Route path="/PA/monitorrevisaoanalisegrade" element={<PAMonitorRevisaoAnaliseGrade />} />
    </Route>

    {/* <Route path="/" element={<PrivateRoute areas={['Monitor Checklist - PA']} />}>
      <Route path="/PA/checklist" element={<NewPAChecklistProvider />} />
    </Route> */}

    <Route path="/" element={<PrivateRoute areas={['Monitor Revisao - PA']} />}>
      <Route path="/PA/revisao-segunda-qualidade-nv" element={<PARevisaoSegundaQualidadeNVProvider />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Monitor Revisao - PA']} />}>
      <Route path="/PA/revisao" element={<PARevisaoProvider />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Monitor Checklist - PA']} />}>
      <Route path="/PA/monitorchecklist" element={
        <PAChecklistProvider>
          <PAMonitorChecklist />
        </PAChecklistProvider>
      } />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Monitor Checklist - PA']} />}>
      <Route path="/PA/reversa" element={
        <PAReversaProvider>
          <PAReversaMain />
        </PAReversaProvider>
      } />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Adm - PA']} />}>
      <Route path="/PA/gerar-laudo" element={<PAGerarLaudoProvider />} />
    </Route>
    {/* <Route path="/" element={<PrivateRoute areas={['Adm - PA']}/>}>
      <Route path="/PA/ADM/controleenvioaprovacao" element={<PAAdmControleEnvioAprovacao />} />
    </Route> */}
    {/* <Route path="/" element={<PrivateRoute areas={['Adm - PA']}/>}>
      <Route path="/PA/ADM/direcionamentopecas" element={<PAAdmDirecionamentoPecas />} />
    </Route> */}
    <Route path="/" element={<PrivateRoute areas={['Adm - PA']} />}>
      <Route path="/PA/ADM/orientacaoconserto" element={<PAAdmOrientacaoConserto />} />
    </Route>
    <Route path="/" element={<PrivateRoute areas={['Adm - PA']} />}>
      <Route path="/PA/ADM/medidas" element={<PAAdmMedidas />} />
    </Route>
    <Route path="/" element={<PrivateRoute areas={['Adm - PA']} />}>
      <Route path="/PA/ADM/devolucaopa" element={<PAAdmDevolucaoPa />} />
    </Route>
    <Route path="/" element={<PrivateRoute areas={['Adm - PA']} />}>
      <Route path="/PA/ADM/alteracaorevisaochecklist" element={<PAAdmAlteracaoRevisaoChecklist />} />
    </Route>
    <Route path="/" element={<PrivateRoute areas={['Adm - PA']} />}>
      <Route path="/PA/ADM/entradafiscal" element={<PAAdmEntradaFiscal />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Controle Aprovacao - PA']} />}>
      <Route path="/PA/controleaprovacao" element={<PAControleAprovacao />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Revisao Externa - PA']} />}>
      <Route path="/PA/revisaoexterna" element={<PARevisaoExterna />} />
    </Route>

    {/* <Route path="/revisaoimportado" element={<PARevisaoImportado />} /> */}

    {/* ANÁLISE LOJA */}
    <Route path="/" element={<PrivateRoute areas={['Formulario Analise - PA']} />}>
      <Route path="/PA/formularioanalise" element={<FormularioAnalise />} />
    </Route>

    {/* DEVOLUÇÃO PA */}
    <Route path="/" element={<PrivateRoute areas={['Devolucao - PA']} />}>
      <Route path="/PA/devolucao" element={<Devolucao />} />
    </Route>
    <Route path="/" element={<PrivateRoute areas={['Agend Dev - PA']} />}>
      <Route path="/PA/agendamentodevolucao" element={<PAAgendamentoDevolucao />} />
    </Route>
    <Route path="/" element={<PrivateRoute areas={['Devolucao - PA']} />}>
      <Route path="/PA/controledevolucao" element={<PAControleDevolucao />} />
    </Route>
    <Route path="/corte/planejamentodiariocorte" element={<PlanejamentoDiarioCorte />} />
    <Route path="/" element={<PrivateRoute areas={['Corte']} />}>
      <Route path="/corte/planejamentodiariocorte" element={<PlanejamentoDiarioCorte />} />
    </Route>
    <Route path="/" element={<PrivateRoute areas={['Corte']} />}>
      <Route path="/pickingmateriais" element={<PickingMateriais />} />
      <Route path="/corte/reclassificacaotipocorte" element={<Corte />} />
    </Route>

    <Route path="/" element={<PrivateRoute areas={['Corte - Enfesto']} acesso={'Corte - Controle de Enfesto'} />}>
      <Route path="/corte/controleenfesto/selecaoriscos" element={<SelecaoRiscos />} />
      <Route
        path="/corte/controleenfesto/lancamentocad"
        element={
          <LancamentoCadProvider>
            <LancamentoCad />
          </LancamentoCadProvider>
        }
      />
      <Route
        path="/corte/controleenfesto/priorizacaoops"
        element={
          <PriorizacaoOpsProvider>
            <PriorizacaoOps />
          </PriorizacaoOpsProvider>
        }
      />
      <Route
        path="/corte/controleenfesto/distribuicaodemanda"
        element={
          <DistribuicaoDemandaProvider>
            <DistribuicaoDemanda />
          </DistribuicaoDemandaProvider>
        }
      />
      <Route
        path="/corte/controleenfesto/alteracaodemanda"
        element={
          <AlteracaoDemandaProvider>
            <AlteracaoDemanda />
          </AlteracaoDemandaProvider>
        }
      />

      <Route path="/" element={<PrivateRoute acesso={'Corte - Controle de Enfesto - Enfesto'} />}>
        <Route path="/corte/controleenfesto/enfesto" element={<EnfestoProvider><Enfesto /></EnfestoProvider>} />
      </Route>

      <Route
        path="/corte/controleenfesto/acompanhamentoenfesto"
        element={
          <AcompanhamentoEnfestoProvider>
            <AcompanhamentoEnfesto />
          </AcompanhamentoEnfestoProvider>
        }
      />
    </Route>

    {/* DESFAZER PA */}
    <Route path="/" element={<PrivateRoute areas={['Desfazer']} acesso={'pa_desfazer'} />}>
      <Route path="/PA/desfazer" element={<PADesfazerMain />} />
    </Route>

    {/* FARM GLOBAL */}
    <Route path="/" element={<PrivateRoute areas={['Qualidade - Farm Global']} acesso={'Qualidade - Farm Global'}/>}>
      <Route path="/FG/materiaiscomposicao" element={<MateriaisComposicao />} />
      <Route path="/FG/testedebasesolicitar" element={<FGTesteDeBase />} />
      <Route path="/FG/laboratorio/testedebase" element={<FGTesteDeBaseLaboratorio />} />
      <Route path="/FG/dpp" element={<FGReportsFarmGlobalProvider />} />
    </Route> 

    <Route path="/mp" element={<PrivateRoute areas={['Processo Materia Prima - MP']}/>}>
      <Route
          path="/mp/triangulacao"
          element={
            <TriangulacaoProvider>
              <Triangulacao />
            </TriangulacaoProvider>
          }
      />
    </Route>

  </Routes>
);

export default Router;
