import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import styles from '../../../../styles/Corte/ControleEnfesto/AlteracaoDemanda.module.scss';
import { useAlteracaoDemanda } from '../../../../contexts/Corte/ControleEnfesto/AlteracaoDemandaContext'; 
import DadosAccordion from './DadosAccordion';

const ModalAlteraFolha = () => {

    const { updateFolhas, handleCloseModal, handleShowConfirmacao, showConfirmacao, handleCloseConfirmacao, showModal, handleQtdeFolhas, qtdeFolhas } = useAlteracaoDemanda();

    return (
        <>
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Insira a nova quantidade de folhas:</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <DadosAccordion />
                    <Form>
                        <Form.Group className={styles.filterInputModal}>
                            <Form.Control
                                placeholder="Quantidade de Folhas"
                                type='text'
                                value={qtdeFolhas || ''}
                                onChange={e => handleQtdeFolhas(e)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-danger"
                        className={styles.modalBtn}
                        onClick={handleCloseModal}
                    >
                        Voltar
                    </Button>
                    <Button
                        variant={qtdeFolhas ? "outline-success" : "secondary"}
                        disabled={!qtdeFolhas}
                        className={styles.modalBtn}
                        onClick={handleShowConfirmacao}
                    >
                        Alterar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showConfirmacao}
                onHide={handleCloseConfirmacao}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Tem certeza?</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <span>A nova quantidade será de <strong>{qtdeFolhas}</strong> folhas.</span>
                </Modal.Body>

                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-danger"
                        className={styles.modalBtn}
                        onClick={handleCloseConfirmacao}
                    >
                        Não
                    </Button>
                    <Button
                        variant="outline-success"
                        className={styles.modalBtn}
                        onClick={updateFolhas}
                    >
                        Sim
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalAlteraFolha;
