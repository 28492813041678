import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import styles from '../../../styles/MP/Triangulacao.module.scss';
import { useTriangulacao } from '../../../contexts/MP/TriangulacaoContext'; 

const ModalReceb = () => {

    const { setShowRecebConfirm, showRecebConfirm, recebeAmostra, linhaSelecionada, handleCloseReceb, showReceb, aprovReceb, setAprovReceb, obsReprov, setObsReprov, setShowReceb } = useTriangulacao();

    const handleShowRecebConfirm = () => {
        setShowReceb(false)
        setShowRecebConfirm(true)
    }

    const handleCloseRecebConfirm = () => {
        setShowRecebConfirm(false)
        setShowReceb(true)
    }

    return (
        <>
            <Modal
                show={showReceb}
                onHide={handleCloseReceb}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Registro de Recebimento</Modal.Title>
                </Modal.Header>
                <Modal.Body className={styles.bodyReceb}>
                    <Form>
                        <div
                            className={styles.divRecebBtn}
                        >
                            <Button
                                className={styles.modalBtn}
                                variant={aprovReceb === false ? 'danger' : 'outline-danger'}
                                onClick={() => setAprovReceb(false)}
                            >
                                Reprovado
                            </Button>
                            <Button
                                className={styles.modalBtn}
                                variant={aprovReceb === true ? 'success' : 'outline-success'}
                                onClick={() => {setAprovReceb(true); setObsReprov('')}}
                            >
                                Aprovado
                            </Button>
                        </div>
                        { aprovReceb === false && 
                            <div>
                                <Form.Group className={styles.formGroup}>
                                    <Form.Label className={styles.texAreatLabel}>Motivo de Recusa</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        className={styles.textAreaControl}
                                        onChange={e => setObsReprov(e.target.value)}
                                        value={obsReprov || ''}
                                    />
                                </Form.Group>
                            </div>
                        }
                    </Form>

                </Modal.Body>
                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-secondary"
                        className={styles.modalBtn}
                        onClick={handleCloseReceb}
                    >
                        Voltar
                    </Button>
                    <Button
                        variant="primary"
                        className={styles.modalBtn}
                        disabled={aprovReceb === false ? !obsReprov : aprovReceb === undefined}
                        onClick={handleShowRecebConfirm}
                    >
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showRecebConfirm}
                onHide={handleCloseRecebConfirm}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Registro de Recebimento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Tem certeza que desja registrar o recebimento do material 
                    <strong> {linhaSelecionada.Material}/{linhaSelecionada.CorMaterial} - {linhaSelecionada.DescMaterial} </strong> 
                    como {aprovReceb ? <strong style={{color: 'green'}}>Aprovado</strong> : <strong style={{color: 'red'}}>Reprovado</strong>}?
                </Modal.Body>
                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-secondary"
                        className={styles.modalBtn}
                        onClick={handleCloseRecebConfirm}
                    >
                        Não
                    </Button>
                    <Button
                        variant="primary"
                        className={styles.modalBtn}
                        onClick={recebeAmostra}
                    >
                        Sim
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalReceb;
