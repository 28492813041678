import React from 'react';
import Tabela from '../../Misc/Tabela/Tabela';
import TooltipComponent from '../../Misc/Tooltip/TooltipComponent';
import MaterialIcon from 'material-icons-react';
import { Button } from 'react-bootstrap';

import { useTriangulacao } from '../../../contexts/MP/TriangulacaoContext'; 


const MainTable = () => {

    const { mainData, loading, handleShowModal, handleShowReceb, handleShowPDF } = useTriangulacao();

    const customStyles = {
        headCells: { style: { backgroundColor: '#ffffff', justifyContent: 'center', color: '#030302' } },
        cells: { style: { justifyContent: 'center' } },
        rows: { style: { cursor: 'pointer' } }
    }

    const tooltipStyle = {whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}

    const conditionalRowStyles = [
        {
          when: row => row.StatusFinal !== 45 && row.StatusFinal !== 4  && row.StatusFinal !== 8,
          style: { cursor: 'auto' }
        }
    ];

    const rowColumns = [
        { name: 'STATUS', selector: row => row.DescricaoStatusFinal, atr: 'DescricaoStatusFinal', sortable: true, width: '200px',
          cell: row => <strong><em><TooltipComponent text={row.DescricaoStatusFinal} tooltip={row.DescricaoStatusFinal} placement='top' style={tooltipStyle} /></em></strong>  },        
        {
          cell: row => {
            return (
              row.ArquivoLaudo ?
              <span style={{cursor: 'pointer'}}><MaterialIcon icon='description' color='#0D6EFD' size={30} onClick={() => handleShowPDF(row.ArquivoLaudo)}/></span>                
              : null
            )
        }, name: 'LAUDO'
        },
        { name: 'PEDIDO', selector: row => row.Pedido, atr: 'Pedido', width: '100px', sortable: true,
          cell: row => <TooltipComponent text={row.Pedido} tooltip={row.Pedido} placement='top' style={tooltipStyle} /> },
        { name: 'TESTE BASE', selector: row => row.DescricaoStatusTesteBase, atr: 'DescricaoStatusTesteBase', width: '300px', sortable: true,
          cell: row => <TooltipComponent text={row.DescricaoStatusTesteBase} tooltip={row.DescricaoStatusTesteBase} placement='top' style={tooltipStyle} /> },
        { name: 'MATERIAL', selector: row => row.Material, atr: 'Material', width: '110px', sortable: true,
          cell: row => <TooltipComponent text={row.Material} tooltip={row.Material} placement='top' style={tooltipStyle} /> },
        { name: 'COR', selector: row => row.CorMaterial, atr: 'CorMaterial', width: '100px',
          cell: row => <TooltipComponent text={row.CorMaterial} tooltip={row.CorMaterial} placement='top' style={tooltipStyle} /> },
        { name: 'FORNECEDOR', selector: row => row.Fornecedor, atr: 'Fornecedor', width: '150px', sortable: true,
          cell: row => <TooltipComponent text={row.Fornecedor} tooltip={row.Fornecedor} placement='top' style={tooltipStyle} /> },
        { name: 'DESCRIÇÃO MATERIAL', selector: row => row.DescMaterial, atr: 'DescMaterial', width: '300px',
          cell: row => <TooltipComponent text={row.DescMaterial} tooltip={row.DescMaterial} placement='top' style={tooltipStyle} />
         },
        { name: 'DESCRIÇÃO COR', selector: row => row.DescCor, atr: 'DescCor', width: '300px',
          cell: row => <TooltipComponent text={row.DescCor} tooltip={row.DescCor} placement='top' style={tooltipStyle} />
         },
         {
          cell: row => {
            return (
              row.RecebimentoAmostra === 0 ?
                <Button onClick={() => handleShowReceb(row)} variant='outline-warning'>Registrar Recebimento</Button>
              : null
            )
          }, width: '183px'
        }
    ];

    return (
        <section style={{ margin: '0px 20px 20px' }}>
            <Tabela
                columns={rowColumns}
                data={mainData}
                pending={loading}
                customStylesOverrider={customStyles}
                highlightOnHover={true}
                rowClickedFn={handleShowModal}
                paginationPerPage={20}
                conditionalRowStyles={conditionalRowStyles}
            />
        </section>
    );
};

export default MainTable;
