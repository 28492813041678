import { createContext, useEffect, useContext, useState, useCallback } from 'react';
import { api } from '../../services/api';
import { toast } from 'react-toastify';
import CookiesService from '../../services/cookies';
import { toDatetime } from '../../utils/dateUtils';
import { useMisc } from '../MiscContext';

const TriangulacaoContext = createContext();
export const useTriangulacao = () => useContext(TriangulacaoContext);

export const TriangulacaoProvider = ({ children }) => {

  const { setShowLoading, setToast } = useMisc();

  const user = CookiesService.getUserLogin();

  const [loading, setLoading] = useState(false);

  const [mainData, setMainData] = useState([]);
  const [testesData, setTestesData] = useState([]);
  const [testesRowData, setTestesRowData] = useState([]);
  const [indicesData, setIndicesData] = useState([]);
  const [statusData, setStatusData] = useState([]);

  const [filterObj, setFilterObj] = useState({
    pedido: '',
    material: '',
    cor_material: '',
    fornecedor: '',
    status: ''
  });

  const [linhaSelecionada, setLinhaSelecionada] = useState({})
  const [testesSelecionados, setTestesSelecionados] = useState([
    {
      "IdTeste": 13,
      "DescricaoTeste": "FICHA TÉCNICA"
    },
    {
      "IdTeste": 7,
      "DescricaoTeste": "GRAMATURA"
    },
    {
      "IdTeste": 18,
      "DescricaoTeste": "LARGURA"
    }
  ])

  const [showModal, setShowModal] = useState(false);
  const [showReceb, setShowReceb] = useState(false);
  const [showRecebConfirm, setShowRecebConfirm] = useState(false)
  const [showConfirmTeste, setShowConfirmTeste] = useState(false);

  const [aprovReceb, setAprovReceb] = useState();
  const [obsReprov, setObsReprov] = useState('')

  const [infoTeste, setInfoTeste] = useState([
    { IdTeste: 13, Status: null, Indice: null, Observacao: null },
    { IdTeste: 7, Status: null, Gramatura: null, GramaturaFornecedor: null, Observacao: null },
    { IdTeste: 18, Status: null, Largura: null, LarguraFornecedor: null, Observacao: null },
  ])

  const [difTesteGram, setDifTesteGram] = useState();
  const [difTesteLarg, setDifTesteLarg] = useState();

  const [obsLab, setObsLab] = useState('')
  const [obsFinal, setObsFinal] = useState('')

  const[habilitaConfirmar, isHabilitaConfirmar] = useState(true)

  const indicesNaoObrigatorios = [
    9, 5, 8, 12, 13, 15, 16, 17, 18
  ]

  const percentFail = [
    '2.1% a 3%',
    '3.1% a 4%',
    '4.1% a 5%',
    '5.1% a 6%',
    '6.1% a 7%',
    '>7.1%'       
  ]

  const handleShowModal = (row) => {
    if (row.StatusFinal === 45) {
      setLinhaSelecionada(row)
      setShowModal(true)
    } else if (row.StatusFinal === 4 || row.StatusFinal === 8) {
      fetchTestesById(row)
      setShowModal(true)
      setObsLab(row.ObsLaboratorio)
      setObsFinal(row.ObsFinal)
    } else return
  }

  const handleCloseModal = () => {
    resetRowModal()
    setShowModal(false)
  }

  const handleShowReceb = (row) => {
    setLinhaSelecionada(row)
    setShowReceb(true)
  }

  const handleCloseReceb = () => {
    setLinhaSelecionada({})
    setShowReceb(false)
    setAprovReceb()
  } 

  const handleShowConfirmTeste = () => {
    const temConflito = infoTeste.some(teste => 
      (teste.Status === 'Aprovado' && teste.Indice === 'REPROVADO') || 
      (teste.Status === 'Reprovado' && teste.Indice === 'APROVADO')
    );
  
    const conflitoDesvio = infoTeste.some(teste => 
      [6, 14].includes(teste.IdTeste) ? percentFail.includes(teste.Indice) && teste.Status === 'Aprovado' : null
    );
  
    if (temConflito) {
      setToast('error', 'Status não pode ser APROVADO e Índice REPROVADO ou vice-versa.');
      return
    }
    if (conflitoDesvio){
      setToast('error', 'Status não pode ser APROVADO e Índice 2.1% a 3% ou maior nos testes de Desvio de Estampa ou Desvio de Trama.');
      return
    }
    setShowModal(false);
    setShowConfirmTeste(true);
  };

  const handleCloseConfirmTeste = () => {
    setShowConfirmTeste(false)
    setShowModal(true)
  }

  const handleSelecionaTeste = (e, teste) => {
    const isChecked = e.target.checked;
    const updatedArray = isChecked
      ? [...testesSelecionados, teste]
      : testesSelecionados.filter(item => item.IdTeste !== teste.IdTeste);

    const updatedTestes = isChecked
      ? [...infoTeste, { IdTeste: teste.IdTeste, Status: null, Indice: null, Observacao: null }]
      : infoTeste.filter(item => item.IdTeste !== teste.IdTeste);

    setTestesSelecionados(updatedArray);
    setInfoTeste(updatedTestes);
  }

  const handleInfoTeste = useCallback((teste, campo, valor) => {
    const regex = /^\d*(?:\.\d{0,2})?$/

    if ((campo === 'Largura' || campo === 'LarguraFornecedor' || campo === 'Gramatura' || campo === 'GramaturaFornecedor') && !regex.test(valor)) return

    setInfoTeste(prevState => {
      const index = prevState.findIndex(item => item.IdTeste === teste);
      if (index !== -1) {
        const updatedItem = { ...prevState[index], [campo]: valor };
        return prevState.map(item => item.IdTeste === teste ? updatedItem : item);
      } else {
        return [...prevState, { IdTeste: teste, [campo]: valor }];
      }
    });
  }, []);

  const resetFilterObj = () => { 
    setFilterObj({
      pedido: '',
      material: '',
      cor_material: '',
      fornecedor: '',
      status: ''
    })
  }

  const resetReprov = () => {
    resetFilterObj()
    setLinhaSelecionada({})
    setAprovReceb()
    setObsReprov('')
  }

  const resetRowModal = () => {
    setLinhaSelecionada({});
    setTestesSelecionados([
      {
        "IdTeste": 13,
        "DescricaoTeste": "FICHA TÉCNICA"
      },
      {
        "IdTeste": 7,
        "DescricaoTeste": "GRAMATURA"
      },
      {
        "IdTeste": 18,
        "DescricaoTeste": "LARGURA"
      }
    ]);
    setInfoTeste([
      { IdTeste: 13, Status: null, Indice: null, Observacao: null },
      { IdTeste: 7, Status: null, Gramatura: null, GramaturaFornecedor: null,  Observacao: null },
      { IdTeste: 18, Status: null, Largura: null, LarguraFornecedor: null, Observacao: null },
    ]);
    setTestesRowData([]);
    setObsLab('');
    setObsFinal('');
  }

  const fetchTriangulacao = async () => {
    setLoading(true);
    const params = { ...filterObj}
    try {
      const res = await api.get('Mp/Triangulacao/get-rows', { params });
      setMainData(res.data.resPrincipal);
      setTestesData(res.data.resTestes);
      setIndicesData(res.data.resIndices);
      setStatusData(res.data.resStatus);
    } catch (e) {
      toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre em contato com o suporte');
      console.error(`Erro na requisição - ${e}`);
    } finally {
      resetFilterObj()  
      setLoading(false);
    }
  };

  const recebeAmostra = async () => {
    setShowRecebConfirm(false)
    setShowLoading(true)
    const send = { ...linhaSelecionada, aprovReceb, obsReprov, usuario: user, dataReceb: toDatetime(new Date()) }
    try {
      const res = await api.post('Mp/Triangulacao/receb-amostra', { send });
      if (res.status === 200) {
        toast.success('Recebimento de amostra feito com sucesso!');
        resetReprov()
        fetchTriangulacao()
      } else toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
    } catch (e) {
      setShowRecebConfirm(true)
      toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre em contato com o suporte');
      console.error(`Erro na requisição - ${e}`);
    } finally {
      setShowLoading(false)
    }
  };

  const finalizaTeste = async () => {
    setShowConfirmTeste(false)
    setShowLoading(true)
    const send = { linhaSelecionada, obsLab, obsFinal, usuarioRegistro: user, dataRegistro: toDatetime(new Date()), infoTeste }
    try {
      const res = await api.post('Mp/Triangulacao/process-test', { send });
      if (res.status === 200) {
        toast.success('Testes incluídos com sucesso');
        resetRowModal();
        fetchTriangulacao();
      } else toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
    } catch (e) {
      setShowConfirmTeste(true)
      toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre em contato com o suporte');
      console.error(`Erro na requisição - ${e}`);
    } finally {
      setShowLoading(false)
    }
  };

  const fetchTestesById = async (row) => {
    setLoading(true);
    const params = { ...row }
    try {
      const res = await api.get('Mp/Triangulacao/get-testes-by-id', { params });
      setInfoTeste(res.data);
      setTestesRowData(res.data);
      setTestesSelecionados(res.data);
      setLinhaSelecionada(row);
    } catch (e) {
      toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre em contato com o suporte');
      console.error(`Erro na requisição - ${e}`);
    } finally {
      setLoading(false);
    }
  };

  const atualizaTeste = async () => {
    setShowConfirmTeste(false)
    setShowLoading(true)

    const send = { linhaSelecionada, obsLab, obsFinal, usuarioRegistro: user, dataRegistro: toDatetime(new Date()), infoTeste }
    try {
      const res = await api.post('Mp/Triangulacao/upsert-test', { send });
      if (res.status === 200) {
        toast.success('Testes incluídos com sucesso');
        resetRowModal();
        fetchTriangulacao();
      } else toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
    } catch (e) {
      setShowConfirmTeste(true)
      toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre em contato com o suporte');
      console.error(`Erro na requisição - ${e}`);
    } finally {
      setShowLoading(false)
    }
  };

  const handleShowPDF = async (fileName) => {      
    console.log(fileName)
    try {            
      const params = { nome: fileName  }
        const res = await api.get('/Mp/Triangulacao/ler-laudo/',{params} );
        if (res.data.statusCode && res.data.statusCode === 404) return toast.warning(res.data.message);
        window.open(res.data[0], '_blank');
    } catch (e) {
        toast.error('Ocorreu um erro ao baixar o arquivo.');
    }
}

  useEffect(() => {
    fetchTriangulacao()
  }, [])

  useEffect(() => {
    const habilita = infoTeste.every(teste => {
      if (teste.IdTeste === 7)
        return teste.Status && teste.Gramatura && teste.GramaturaFornecedor
      if (teste.IdTeste === 18)
        return teste.Status && teste.Largura && teste.LarguraFornecedor
      if (!indicesNaoObrigatorios.includes(teste.IdTeste))
        return teste.Indice && teste.Status
      if (teste.IdTeste !== 7  && teste.IdTeste !== 18)
        return teste.Status
    })
    isHabilitaConfirmar(habilita)
  }, [infoTeste])

  return (
    <TriangulacaoContext.Provider
      value={{ atualizaTeste, testesRowData, statusData, difTesteGram, setDifTesteGram, difTesteLarg, setDifTesteLarg, handleCloseConfirmTeste, handleShowConfirmTeste, showConfirmTeste, finalizaTeste, infoTeste, setInfoTeste, habilitaConfirmar, handleInfoTeste, testesSelecionados, handleSelecionaTeste, setObsFinal, setObsLab, obsFinal, obsLab, testesData, indicesData, setShowRecebConfirm, showRecebConfirm, recebeAmostra, setShowReceb, obsReprov, setObsReprov, aprovReceb, setAprovReceb, linhaSelecionada, mainData, loading, filterObj, setFilterObj, fetchTriangulacao, handleShowModal, handleCloseModal, showModal, handleShowReceb, handleCloseReceb, showReceb, handleShowPDF }}
    >
      {children}
    </TriangulacaoContext.Provider>
  )
}